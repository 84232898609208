import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from '../../constants/firebase';
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';
import Progress from '../util/Progress';

// COMPONENTES
import Maqueta from '../maqueta/Maqueta'
import VerificarEmail from './VerificarEmail';

//REDUX
import ActualizarUsuario from '../../store/usuario/action';

// IMAGENES
import logo from '../../assets/img/logo_login.png';
import novisibility from '../../assets/icon/hidden.svg';
import visibility from '../../assets/icon/visibility.svg';
import back from '../../assets/icon/back.svg';
import { analytics } from 'firebase';
import ReferenciaComercial from '../modal/ReferenciaComercial';

//CAPTCHAR
import ReCAPTCHA from 'react-google-recaptcha';

const Login = (props) => {
    const [usuario, setUsuario] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [errorg, setErrorg] = React.useState("");
    const [errors, setErrors] = React.useState("");
    const [hidden, setHidden] = React.useState(false);
    const [slide, setSlide] = React.useState(false);
    const [verificar, setVerificar] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [ubicacion, setUbicacion] = React.useState('');
    const [captchar, setcaptchar] = useState(false);
    const captchaRef = useRef(null);

    const handleRegistrar = () => {
        props.history.push('/registro')
    }

    const handleRecuperarclave = () => {
        props.history.push('/recuperar-clave');
    }

    const handleComprobante = item => {
        setModal(true);
    }

    const handleToken = (token) => {
        if (token) {
            return true;
        }

        //*-dejar en false
         return false;      
    }

    const handleSubmit = event => {
        setLoader(true);
        let token = captchaRef.current.getValue();
        if (handleToken(token)) {

            const url = Constants.URL_BASE + Constants.WSAUTH
            axios.post(url, {
                'usuario': usuario,
                'clave': password
            })
                .then(res => {
                    setLoader(false);
                    setHidden(false);
                    setSlide(true);
                    if (res.data) {
                        firebase.analytics().logEvent("login", { usuario: res.data.cliente.usuario });
                        props.ActualizarUsuario(res.data.cliente);
                        Auth.setToken(res.data.token);
                        Auth.setUbicacion(ubicacion);
                        Auth.setDoc(res.data.cliente.usuario);
                        Auth.setTipoUsuario(res.data.cliente.tipoUsuario);

                        let url = Constants.URL_BASE + Constants.WSSUCURSALES + "?usuario=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();
                        axios.get(url).then(res => {
                            let data = res.data || [];
                            Auth.setSucursal({
                                ciudad: data[0].ciudad,
                                codListaP: data[0].codListaP,
                                codigoSac: data[0].codigoSac,
                                direccion: data[0].direccion,
                                nombre: data[0].nombre,
                                razonSocial: data[0].razonSocial,
                                nit: data[0].nit,
                                condicionPago: data[0].condicionPago,
                                cupo: data[0].cupo,
                                codDiasEntrega: data[0].codDiasEntrega,
                                pedidoMinimo: data[0].pedidoMinimo,
                                pedidoMaximo: data[0].pedidoMaximo,
                                bloqueado: data[0].bloqueado,
                                agencia: data[0].agencia,
                                grupoCliente: data[0].grupoCliente,
                                obsequiPrimerPed: data[0].obsequiPrimerPed,
                                diasPedido: data[0].diasPedido,
                                bloqueadoCartera: 'N'
                            });
                            props.history.replace('/');
                        })
                    }

                }).catch((err) => {
                    setLoader(false)
                    console.log(err.response.data);

                    if (err.response.data.errors) {
                        setErrors(err.response.data.errors);
                    }

                    if (err.response.data.error) {
                        setErrorg(err.response.data.error);

                        if (err.response.data.code == 'NO_VERIFICADO') {
                            setVerificar(true);
                        }
                    }

                });
        }
        else {
            setLoader(false)
            setErrorg("Por favor, realizar el captcha de manera correcta.");
        }
        event.preventDefault();
    }


    useEffect(() => {
        axios.get('https://geolocation-db.com/json/').then((response) => {
            setUbicacion(response.data);
            console.log(response.data);
        });
        if (!slide) {
            if (document.querySelector(".login-content")) {
                document.querySelector(".login-content").classList.add("show");

                setTimeout(function () {
                    setSlide(true);
                    if (document.querySelector(".login-content"))
                        document.querySelector(".login-content").classList.remove("show");
                }, 4000);
            }
        }
    }, [])

    // VISTAS
    return (
        <div className="login">
            <div className="login-content">
                <Maqueta />
                <div className="login-form" id="login-form">

                    <div className="main">
                        {verificar ? (
                            <form>
                                <VerificarEmail email={"email"} />
                            </form>
                        ) : (
                            <React.Fragment>

                                <div className="logo-img">
                                    <img src={logo} alt="logo app"></img>
                                </div>
                                {loader ? <Progress color={"3a8557"} /> : (
                                    <form onSubmit={handleSubmit}>

                                        <React.Fragment>
                                            <div className="form-control">
                                                <label>Cédula o Documento <span>*</span>:</label>
                                                <input
                                                    className={errors.Email ? "input-error" : null}
                                                    type="text"
                                                    placeholder="Cédula o Documento"
                                                    name="usuario"
                                                    id="usuario"
                                                    onChange={e => setUsuario(e.target.value)}
                                                    value={usuario}>
                                                </input>
                                                {errors.Email ? (
                                                    <span className="error">
                                                        <b>{errors.Email}</b>
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="form-control">
                                                <label>Contraseña <span>*</span>:</label>
                                                <input
                                                    className={errors.Clave ? "input-error" : null}
                                                    type={hidden ? "text" : "password"}
                                                    placeholder="Contraseña"
                                                    name="password"
                                                    id="password"
                                                    onChange={e => setPassword(e.target.value)}
                                                    value={password}>

                                                </input>
                                                {hidden ? (
                                                    <div>
                                                        <img className="button-hidden" src={visibility} onClick={e => setHidden(false)} alt="icon minus" width="15px" height="15px"></img>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img className="button-hidden" src={novisibility} onClick={e => setHidden(true)} alt="icon minus" width="15px" height="15px"></img>
                                                    </div>
                                                )}

                                                {errors.Clave ? (
                                                    <span className="error">
                                                        <b>{errors.Clave}</b>
                                                    </span>
                                                ) : null}


                                            </div>

                                            {errorg.length > 0 ? (
                                                <span className="error">
                                                    <p>{errorg}</p>
                                                </span>
                                            ) : null}

                                            <div className="form-link">
                                                <a onClick={e => handleRecuperarclave()}>¿Olvidé mi contraseña?</a>
                                            </div>

                                            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
                                            <button type="submit" className="btn-large" >Ingresar</button>
                                            <button type="button" className="btn-large outline" onClick={() => handleRegistrar()}>Registrarse</button>
                                        </React.Fragment>
                                    </form>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
            {modal ? <ReferenciaComercial login={true} showModal={setModal} /> : null}
        </div>
    )
}


export default withRouter(connect(null, { ActualizarUsuario })(Login));