import React, { useState, useEffect } from 'react';
import Auth from '../../helpers/Auth';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// IMAGENES
import datos from '../../assets/icon/datos.svg';
import perfil from '../../assets/icon/perfilusers.svg';
import information from '../../assets/icon/information.svg';
import history from '../../assets/icon/history.svg';
import exit from '../../assets/icon/exit.svg';
import pagos from '../../assets/icon/pagos.svg';

import { selectActiveUser } from '../../store/usuario/reducer';
import AlertModal from '../modal/AlertModal';
import { selectActiveAlert } from '../../store/alerta/reducer';
import AlertShow from '../../store/alerta/action';

const Menu = (props) => {

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };


    const handleRedirect = item => {
        props.history.push(item);
    }

    const handleComprobante = item => {
        props.AlertShow(true);
    }

    const handleLogout = () => {
        Auth.signout();
        window.location.reload(true);
    }

    return (
        <div className="menu">
            <ul>
                {(Auth.getToken()&&Auth.getDoc()!=='1111111111') ? (
                    <React.Fragment>

                        <li>
                            <a onClick={() => handleRedirect('/datos')}>
                                <img src={datos} width="15px" height="15px" />
                                Mis datos
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleRedirect('/pedidos')}>
                                <img src={history} width="15px" height="15px" />
                                Historial Pedidos
                            </a>
                        </li>

                        {props.usuario.tipoUsuario == "M" && props.usuario.sucursales != "1" ?
                            <li>
                                <a onClick={() => handleRedirect('/creacion-usuarios')}>
                                    <img src={perfil} width="15px" height="15px" />
                                    Administrador de usuarios
                                </a>
                            </li> : null}

                       {/*  <li>
                            <a onClick={() => handleClick()}>
                                <img src={pagos} id="pagos" width="15px" height="15px" />
                                Pagos y Cartera
                            </a>
                            {open ?
                                <ul class="interior">
                                    <li><a onClick={() => handleRedirect('/cartera')}> Realiza tus pagos</a></li>
                                    <li><a onClick={() => handleRedirect('/estado-cuenta')}> Tu estado de cuenta</a></li>
                                    <li><a onClick={() => handleComprobante()}>Referencia comercial</a></li>
                                    <li><a href="#r">Solicita tu crédito - próximamente</a></li>
                                    <li><a href="#r">Certificaciones - próximamente</a></li>
                                </ul> : null}

                        </li> */}

                        {/* <li>
                            <a onClick={() => handleRedirect('/preguntas-frecuentes')}>
                                <img src={information} width="15px" height="15px" />
                                Preguntas frecuentes
                            </a>
                        </li>
                        <li>
                            <a href="/manual" target="_blank">
                                <img src={information}  width="15px" height="15px" />
                                Manual de usuario
                            </a>
                        </li> */}
                        <li>
                            <a onClick={() => handleLogout()}>
                                <img src={exit} width="15px" height="15px" />
                                Salir
                            </a>
                        </li>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <li>
                            <a onClick={() => handleRedirect('/login')}>
                                <img src={datos} width="15px" height="15px" />
                                Ingresar
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleRedirect('/registro')}>
                                <img src={datos} width="15px" height="15px" />
                                Registrarse
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleRedirect('/preguntas-frecuentes')}>
                                <img src={information} width="15px" height="15px" />
                                Preguntas frecuentes
                            </a>
                        </li>
                        <li>
                            <a href="/manual" target="_blank">
                                <img src={information} width="15px" height="15px" />
                                Manual de usuario
                            </a>
                        </li>
                    </React.Fragment>
                )}
            </ul>
        </div>
    )
}

const mapStateToprops = (state) => {
    return {
        usuario: selectActiveUser(state),
        comprobante: selectActiveAlert(state)
    }
}

//export default Menu;
export default withRouter(connect(mapStateToprops,{ AlertShow })(Menu));