import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import slidebeneficios from '../../assets/img/slidebeneficios.png';
import texto_slider from '../../assets/img/texto_slider.png';
import slidefooter from '../../assets/img/fondo_slider.jpeg';

const Maqueta = (props) => {


    return (
        <React.Fragment>
            <div className="splash" id="splash">
                               
                <div className="slide-footer">
                    <div className="footer-img">
                        <img src={slidefooter} alt="logo app"></img>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Maqueta);