import React, { useEffect } from 'react'
import * as Constants from '../../constants/global'

import close from '../../assets/icon/close_grey.svg';

import { Cookies } from 'react-cookie'

const cookies = new Cookies();

const PublicidadModal = (props) => {

    const handleClose = () => {
        cookies.set("publicidad", "close", {
            maxAge: 60 * 60 * 5
        });
        document.querySelector(".publicidad").classList.remove("show-modal");
    }

    const onClickItem = (item) => {
        window.location.href = item.enlace;
    }

    useEffect(() => {
        console.log(Constants.URL_BASE + 'popup/' + props.popup.imagen);
        document.querySelector(".publicidad").classList.toggle("show-modal");
    }, []);

    return (
        <div className="modal publicidad">
            <div className="modal-content ">
                <span className="close-button" onClick={() => handleClose()}>
                    <img
                        src={close}
                        width="15"
                        height="15"
                        alt="icon close" />
                </span>
                <div className="content" style={{ padding: '0px' }}
                    onClick={() => onClickItem(props.popup)}>
                    <img style={{
                        width: '100%',
                        margin: '0px',
                        height: '100%',
                        borderRadius: '10px;'}}
                                                className="img-banner-publicidad"
                                                src={Constants.URL_BASE + 'popup/' + props.popup.imagen}
                                                onError={(e) => { e.target.onError = null; e.target.onClick = handleClose() }}>
                                            </img>
            </div>
        </div>
        </div >
    )
}

export default PublicidadModal
