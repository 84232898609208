import * as Const from '../conts';
const initialState = { option: "" };

export default (state = initialState, action) => {
    switch (action.type) {
        case Const.ALERT_SHOW:
            return {
                ...state,
                option: action.payload
            };
        default:
            return state;
    }
};

export const selectActiveAlert = state => state.alertReducer.option;