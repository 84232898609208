import * as Const from '../conts';
const initialState = { usuario: "" };

export default (state = initialState, action) => {
    switch (action.type) {
        case Const.SET_USUARIO:
            return {
                ...state,
                usuario: action.payload
            };
        default:
            return state;
    }
};

export const selectActiveUser = state => state.usuarioReducer.usuario;