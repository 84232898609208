import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as Constants from '../../constants/global';
import axios from 'axios';

import Sub_nav from '../navbar/Sub_nav'
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

// REDUX
import { selectActiveUser } from '../../store/usuario/reducer';
import CrearUModal from './DatosCrearUsuarioJR';
import Auth from '../../helpers/Auth';
import Progress from '../util/Progress';
import DatosCrearUsuarioJR from './DatosCrearUsuarioJR';
import detail from '../../assets/icon/detail.svg'

const CreacionUsuarios = (props) => {

    const [cantidad, setCantidad] = useState(0);
    const [crear, setCrear] = useState(false);
    const [loader, setLoader] = useState(false);
    const [sucursal, setSucursal] = useState([]);
    const [usuariosJR, setUsuariosJR] = useState();
    const [editar, setEditar] = useState([]);

    const getCantidad = () => {
        var cant = 0;
        let cart = JSON.parse(localStorage.getItem('carrito')) || {};
        Object.keys(cart).map((item, i) => {
            cant += cart[item].cantidad;
        })
        setCantidad(cant);
    }


    useEffect(() => {
        getCantidad();
        getUsuarios();
    }, [props.usuario])

    const handleAgregarUsuario = (state) => {
        Auth.setListaJR({});
        setEditar([]);
        setCrear(state);
    }

    const getUsuarios = () => {
        setLoader(true)
        setSucursal(Auth.getSucursal());
        let client = props.usuario

        let url = Constants.URL_BASE + Constants.WSUSUARIOSJRCLIENTE + '/' + client.doc;
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            setTimeout(() => {
                setLoader(false)
            }, 2500)
            setUsuariosJR(response.data)

        })
    }

    const getResponse = (response) => {
        if (response) {
            setLoader(true)
            getUsuarios();
        }
        setEditar([]);
        setCrear(false)
    }

    const handleEditar = (item) => {
        var seleccionadas = Auth.getListaJR() || {};
        let lista = item.sucursales;
        let sucursales = [];
        let categorias = [];
        let categoriasComprobar = [];
        let ultimoGrupoC;

        Object.keys(lista).forEach(element =>{
            let grupoC = lista[element].grupoC;
            let id = lista[element].id;
            if(ultimoGrupoC!=grupoC){
                sucursales = [];
                categorias = [];
                categoriasComprobar = [];
                ultimoGrupoC = grupoC;
            }
            let sucursal = lista[element].sucursal;
            let categoria = lista[element].categoria;
            let codigoCategoria = lista[element].codigoCategoria;

            if(!sucursales.includes(sucursal)){
                sucursales.push(sucursal);
            }
            if(!categoriasComprobar.includes(categoria)){
                categorias.push({"0":codigoCategoria,"1": categoria});
                categoriasComprobar.push(categoria);
            }

            seleccionadas[grupoC] = {
                id: grupoC,
                sucursales: sucursales,
                categorias: categorias
            }
            
            setEditar({
                editar: true,
                seleccionadas: seleccionadas,
                item: item
            })
            Auth.setListaJR({});
            setCrear(true);

        });

    }

    return (
        <React.Fragment>

            <div className="navbar navcarrito" >
                <Navbar item={3} />
                <Sub_nav cantidad={cantidad} />
            </div>
            <div className="layout">
                <div className="historial">

                    {usuariosJR ? (
                        <React.Fragment>
                            {loader ? <Progress color={"#0194C5"}></Progress> : (
                                <React.Fragment>
                                    {crear ?
                                        (<React.Fragment>
                                            <DatosCrearUsuarioJR editar={editar} nitUser={props.usuario.doc.toString().concat('', Object.keys(usuariosJR).length + 1)} callback={getResponse.bind(this)} />
                                        </React.Fragment>)
                                        :
                                        (
                                            <React.Fragment>
                                                <a onClick={() => handleAgregarUsuario(1)}>Crear usuario</a>

                                                <div className="table-title">
                                                    <h3>Usuarios creados</h3>
                                                </div>

                                                <div className="table-responsive">

                                                    <table className="mytabla mytabla-responsive">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-left">Id</th>
                                                                <th className="text-left">Usuario</th>
                                                                <th className="text-left">Correo</th>
                                                                <th className="text-left">Telefono</th>
                                                                <th className="text-left">Editar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="table-hover">
                                                            {usuariosJR.map((item, i) => (
                                                                <tr key={i}>

                                                                    <td className="text-center">{item.id}</td>
                                                                    <td className="text-center">{item.usuario}</td>
                                                                    <td className="text-center">{item.correo}</td>
                                                                    <td className="text-center">{item.telefono}</td>
                                                                    <td className="text-left">
                                                                        <a onClick={e => handleEditar(item)}>
                                                                            <img src={detail} alt="icon delete" width="30px" height="30px"></img>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </React.Fragment>
                                        )}





                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        null
                    )}

                </div>
            </div>

            <Footer />
        </React.Fragment >
    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state)
    }
}

export default connect(mapStateToProps)(CreacionUsuarios);