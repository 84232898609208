import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import close from '../../assets/icon/close_grey.svg';
import Auth from '../../helpers/Auth';
import DireccionPlaces from '../util/DireccionPlaces';
import AlertModal from '../modal/AlertModal';
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveUser } from '../../store/usuario/reducer';
import { selectActiveCarrito } from '../../store/carrito/reducer';
const EditModal = (props) => {

    const [alerta, setAlerta] = useState();
    const [error, setError] = useState("");
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [barrios, setBarrios] = useState([]);
    const [departamento, setDepartamento] = useState("NONE");
    const [municipio, setMunicipio] = useState("NONE");
    const [direccion, setDireccion] = useState();
    const [barrio, setBarrio] = useState();
    const [complemento, setComplemento] = useState("");
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const arrayTipo = ["Calle", "Carrera", "Avenida", "Avenida(carrera)", "Avenida(calle)",
        "Circular", "Circunvalar", "Diagonal", "Manzana", "Transversal", "Via"];
    const [dir1, setDir1] = useState("Calle");
    const [dir2, setDir2] = useState("");
    const [dir3, setDir3] = useState("");
    const [dir4, setDir4] = useState("");
    const [departamentoId, setDepartamentoId] = useState(0)
    const [municipioId, setMunicipioId] = useState(0)

    const handleClose = (state) => {
        props.callback(state)
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }

    const handleUpdate = () => {
        handleClose(true)
    }

    const handleUpdateDir = () => {
        console.log("entra");
        setError("")
        var err = error || {};
        if (departamento == "NONE") {
            err['Departamento'] = "El departamento es obligatorio.";
            return setError(err);
        }
        if (municipio == "NONE") {
            err['Municipio'] = "El municipio es obligatorio.";
            return setError(err);
        }
        if (dir2.length < 1) {
            err['numero1'] = "El numero es obligatorio.";
            return setError(err);
        }
        if (dir3.length < 1) {
            err['numero2'] = "El complemento es obligatorio.";
            return setError(err);
        }

        var muni = JSON.parse(municipio);
        var nota = complemento.length > 0 ? complemento : ""
        var direccion = "" + dir1 + " " + dir2 + " N° " + dir3 + "-" + dir4 + ", " + nota
        let url = Constants.URL_BASE + Constants.WSACTUALIZARDIRECCIONCLIENTE + "?nit=" + props.usuario.doc +
            "&departamentoId=" + departamentoId + "&municipioId=" + municipioId + "&direccion=" + direccion
        console.log(url)
        axios.put(url, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            let carrito = Auth.getCarrito()
            Object.keys(carrito).map((item) => {
                delete carrito[item];
                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());
            })
            handleUpdateClient(direccion, muni)
        }).catch((err) => {
            if (err.response) {
                setError(err.response.data.errors)
            }
        })

    }

    const handleUpdateClient = (direccion, muni) => {
        var sucursal = Auth.getSucursal();
        sucursal.ciudad = muni.name;
        sucursal.direccion = direccion;
        Auth.setSucursal(sucursal)
        window.location.reload(true)
        handleClose(true)
    }

    const handleChangeDep = (item) => {
        var depto = JSON.parse(item);
        console.log(depto);
        if (item !== "NONE") {
            setDepartamento(item);
            setMunicipio("NONE");
            setDepartamentoId(depto.codigo)
            getMunicipios(depto.codigo);
            setDireccion("");
            setBarrio("");
        } else {
            setDepartamento(item);
            setMunicipios([]);
            setMunicipio("NONE");
            setDireccion("");
            setBarrio("");
        }
    }


    const handleChangeMun = (item) => {
        if (item !== "NONE") {
            setMunicipio(item);
            setDireccion("");
            setBarrio("");
            getBarrios(JSON.parse(item).id);
            setMunicipioId(JSON.parse(item).id)
        } else {
            getBarrios([]);
            setMunicipio(item);
            setDireccion("");
            setBarrio("");
        }
    }

    const getDepartamentos = () => {
        let url = Constants.URL_BASE + Constants.WSDEPARTAMENTOS + "?co=1"
        axios.get(url).then(res => {
            let data = res.data || [];
            setDepartamentos(data);
        })
    }

    const getMunicipios = (departamentoId) => {
        let url = Constants.URL_BASE + Constants.WSMUNICIPIO + departamentoId + "?co=1"
        axios.get(url).then(res => {
            let data = res.data || [];
            setMunicipios(data);
        })
    }

    const getBarrios = (municpioId) => {
        let url = Constants.URL_BASE + Constants.WSBARRIO + municpioId;
        axios.get(url).then(res => {
            let data = res.data || [];
            setBarrios(data);
        })
    }

    useEffect(() => {

        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
        if (props.editar == 2) {
            getDepartamentos();
            let direccion = props.usuario.direcciones[0];

        }
    }, [props.usuario])

    return (
        <React.Fragment>
            <div className="update">
                <div className="update-content">
                    <div className="update-form" id="update-form">
                        <form>
                            <div className="form-control">
                                <div className="modal modal-sucursal">
                                    <div className="modal-content edit-content">
                                        <div className="title">
                                            {props.editar == 1 ? (
                                                <p>Datos personales</p>
                                            ) : (
                                                <p>Mi dirección</p>
                                            )}

                                            <span className="close-button" onClick={() => handleClose(false)}>
                                                <img
                                                    src={close}
                                                    width="15"
                                                    height="15"
                                                    alt="icon close" />
                                            </span>
                                        </div>
                                        <div className="content">

                                            {props.editar == 1 ? (
                                                <React.Fragment>
                                                    <form >
                                                        <div className="form-control">
                                                            <label>Nombre:</label>
                                                            <input type="text" defaultValue={props.usuario.nombre} placeholder="Nombre completo" onChange={e => props.usuario.nombre = e.target.value}></input>
                                                            {error.Nombres ? (
                                                                <span className="error">
                                                                    <b>{error.Nombres}</b>
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                        <div className="form-control">
                                                            <label>Apellidos:</label>
                                                            <input type="text" placeholder="apellidos" defaultValue={props.usuario.apellido} onChange={e => props.usuario.apellido = e.target.value}></input>
                                                            {error.Apellidos ? (
                                                                <span className="error">
                                                                    <b>{error.Apellidos}</b>
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                        <div className="form-control">
                                                            <label>Celular:</label>
                                                            <input type="number" placeholder="Celular" defaultValue={props.usuario.telefono} onChange={e => props.usuario.telefono = (e.target.value)}></input>
                                                        </div>
                                                        <div className="form-control">
                                                            <label>Correo:</label>
                                                            <input type="email" defaultValue={props.usuario.email} placeholder="Dirección de correo electronico" onChange={e => props.usuario.email = (e.target.value)}></input>
                                                            {error.Email ? (
                                                                <span className="error">
                                                                    <b>{error.Email}</b>
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                        <div className="form-control">
                                                            <label>Contraseña:</label>
                                                            <input type="password" defaultValue={props.usuario.clave} placeholder="Contraseña" onChange={e => props.usuario.clave = (e.target.value)}></input>
                                                            {error.Clave ? (
                                                                <span className="error">
                                                                    <b>{error.Clave}</b>
                                                                </span>
                                                            ) : null}
                                                        </div>

                                                        {error['$.habeasData'] ? (
                                                            <span className="error">
                                                                <b>Por favor acepte terminos y condiciones</b>
                                                            </span>
                                                        ) : null}


                                                        <button type="button" className="btn-large" onClick={() => handleUpdate()}>Actualizar</button>
                                                        <button type="button" className="btn-large outline" onClick={() => handleClose(false)}>Cancelar</button>
                                                    </form>
                                                </React.Fragment>
                                            ) : (
                                                <form>
                                                    <div className="form-control">
                                                        <label>Departamento:</label>
                                                        <select
                                                            className={error.Departamento ? "input-error" : null}
                                                            name="departamentos"
                                                            value={departamento}
                                                            onChange={e => handleChangeDep(e.target.value)}>
                                                            <option selected="true" value="NONE">Seleccione departamento</option>
                                                            {departamentos.map((item, i) => (
                                                                <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                            ))}
                                                        </select>
                                                        {error.Departamento ? (
                                                            <span className="error">
                                                                <b>{error.Departamento}</b>
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                    {municipios ? (
                                                        <div className="form-control">
                                                            <label>Municipio:</label>
                                                            <select
                                                                className={error.Municipio ? "input-error" : null}
                                                                name="municipios"
                                                                value={municipio}
                                                                onChange={e => handleChangeMun(e.target.value)}>
                                                                <option selected="true" value="NONE">Seleccione un municipio</option>
                                                                {municipios.map((item, i) => (
                                                                    <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                                ))}
                                                            </select>
                                                            {error.Municipio ? (
                                                                <span className="error">
                                                                    <b>{error.Municipio}</b>
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    ) :
                                                        <div className="form-control">
                                                            <label>Municipio:</label>
                                                            <select name="municipios" >
                                                                <option>Seleccione un municipio</option>
                                                            </select>
                                                        </div>
                                                    }
                                                    {departamento !== "NONE" && municipio !== "NONE" ? (
                                                        <>

                                                            <label>Dirección <span>*</span>:</label>
                                                            <div className="input-direcion">
                                                                <select defaultValue={dir1} onChange={e => setDir1(e.target.value)} className={error.length > 0 ? "input-error" : null}>
                                                                    {arrayTipo.map((item, i) => (
                                                                        <option key={i} value={item} selected={dir1 === item}>{item}</option>
                                                                    ))}
                                                                </select>
                                                                <div className="direccion-group">
                                                                    <input
                                                                        className={error.numero1 ? "input-error" : null}
                                                                        name="numero1"
                                                                        type="text"
                                                                        placeholder="--"
                                                                        value={dir2}
                                                                        onChange={e => setDir2(e.target.value)}>
                                                                    </input>
                                                                    <label>#</label>
                                                                    <input
                                                                        className={error.numero2 ? "input-error" : null}
                                                                        name="numero2"
                                                                        type="text"
                                                                        placeholder="--"
                                                                        value={dir3}
                                                                        onChange={e => setDir3(e.target.value)}
                                                                    >
                                                                    </input>
                                                                    <label>-</label>
                                                                    <input type="text" placeholder="--" value={dir4} onChange={e => setDir4(e.target.value)} className={error.length > 0 ? "input-error" : null}></input>
                                                                </div>
                                                                <div className="form-control">
                                                                    <label>Piso / Oficina / Apto:</label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Ingrese complemento"
                                                                        value={complemento} onChange={e => setComplemento(e.target.value.replace("-", ""))}>
                                                                    </input>
                                                                </div>

                                                                {error.length > 0 ? (
                                                                    <span className="error">
                                                                        <b>{error}</b>
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </>
                                                    ) : null}
                                                    <button type="button" className="btn-large" onClick={e => handleUpdateDir()} >Actualizar</button>
                                                    <button type="button" className="btn-large outline" onClick={() => handleClose(false)}>Cancelar</button>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
        </React.Fragment>
    )
}


const mapStateToprops = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToprops, { ActualizarCarrito })(EditModal));