import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Constants from '../../constants/global';
import AlertModal from '../modal/AlertModal';

import Auth from '../../helpers/Auth';
import ModalUserSucursales from '../modal/ModalUserSucursales';
import detail from '../../assets/icon/detail.svg'
import eliminar from '../../assets/icon/eliminar.svg'
import Progress from '../util/Progress';

const DatosCrearUsuarioJR = (props) => {

    const [alerta, setAlerta] = useState();
    const [modalSucursal, setModalSucursal] = useState();
    const [error, setError] = useState("");
    const [errorg, setErrorg] = useState("");
    const [loader, setLoader] = useState(false);
    const [loaderB, setLoaderB] = useState(false);

    const [usuario, setUsuario] = useState("");
    const [email, setEmail] = useState("");
    const [celular, setCelular] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [contrasena, setContrasena] = useState();
    const [listaCategorias, setListaCategorias] = useState([]);
    const [listaSucursales, setListaSucursales] = useState([]);
    const [listaSucursalesCopia, setListaSucursalesCopia] = useState([]);
    const [editar, setEditar] = useState([]);
    const [editarUsuario, setEditarUsuario] = useState([]);
    const [copia, setCopia] = useState(false);
    const [listaSucursalesCategorias, setSucursalesCategorias] = useState([]);




    const getSucursales = (edit) => {
        setLoaderB(true);
        var lista = "";
        if (edit) {
            if (Object.keys(Auth.getListaJR()).length > 0) {
                lista = "&listaSucursales=";
                Object.keys(Auth.getListaJR()).forEach(listas => {
                    Object.keys(Auth.getListaJR()[listas].sucursales).forEach(posicion => {
                        lista += Auth.getListaJR()[listas].sucursales[posicion] + "°";
                    })
                })

            }
        }

        let sucursales = [];
        let url = Constants.URL_BASE + Constants.WSSUCURSALESJR + "?usuario=" + Auth.getDoc() + lista;

        axios.get(url).then(res => {
            res.data.map((item, i) => (
                sucursales[i] = { value: item.codigoSac, label: item.codigoSac + " - " + item.direccion }
            ));
            setListaSucursales(sucursales);
            if (!copia) {
                console.log('copia');
                setCopia(true);
                setListaSucursalesCopia(sucursales);
            }
            setLoaderB(false);
        })
    }


    const getCategorias = () => {

        let categorias = [];
        let url = Constants.URL_BASE + Constants.WSCATEGORIAS;
        axios.get(url).then(res => {
            res.data.map((item, i) => (
                categorias[i] = { value: item.id, label: item.nombre }
            ));
            setListaCategorias(categorias)
        })
    }

    useEffect(() => {
        Auth.setListaJR({});
        getCategorias();
        getSucursales(true);
        setUsuario(props.nitUser);
        setEditarUsuario(props.editar);
        handleEditUser();

    }, [props.usuario])

    const handleEditUser = () => {
        console.log(props.editar);
        if (props.editar.editar) {
            setUsuario(props.editar.item.usuario);
            setCelular(props.editar.item.telefono);
            setEmail(props.editar.item.correo);
            setNombre(props.editar.item.nombre);
            setApellido(props.editar.item.apellido);
            Auth.setListaJR(props.editar.seleccionadas);
            setSucursalesCategorias(Auth.getListaJR());
        }

    }

    const handleAddUser = () => {
        if(editarUsuario.editar){
            setLoader(true)
            getSucursales(true);
            setTimeout(() => {
                setLoader(false)
                setModalSucursal(true)
            }, 2500)
        }else{
            setModalSucursal(true)
        }
    }


    const handleCancelar = (state) => {
        props.callback(state)
    }

    const registrarUsuarioJR = () => {
        setErrorg("");
        setError("");
        setLoader(true);

        let url = Constants.URL_BASE + Constants.WSREGISTRO + "/registroUserJunior"

        let detalleSucursal = [];

        Object.keys(listaSucursalesCategorias).forEach(element => {
            let sucursales = listaSucursalesCategorias[element].sucursales;
            let categorias = listaSucursalesCategorias[element].categorias;
            let id = listaSucursalesCategorias[element].id;

            Object.keys(categorias).forEach(categoria => {
                var codigoCate = categorias[categoria][0];
                Object.keys(sucursales).forEach(sucursal => {
                    var codigo = sucursales[sucursal];
                    detalleSucursal.push({
                        "CodigoSAC": codigo,
                        "CodigoCategoria": codigoCate + "",
                        "GrupoC": Number(id)
                    })
                })
            })
        })

        let data = {
            "nombres": nombre,
            "apellidos": apellido,
            "doc": Auth.getDoc(),
            "usuario": usuario,
            "tipoDoc": "CC",
            "telefono": celular,
            "email": email,
            "clave": contrasena,
            "tyC": 1,
            "habeasData": 1,
            "departamentoId": 0,
            "municipioId": 0,
            "barrioId": 0,
            "direccion": "direccion",
            "referencia": "",
            "latitud": 0,
            "longitud": 0,
            "sucursales": detalleSucursal
        }

        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            setLoader(false);
            if (response.data.message) {
                setAlerta({
                    tipo: "success",
                    titulo: "Registro usuario",
                    mensaje: response.data.message,
                    btnOk: "Aceptar",
                    fnOk: function () {
                        handleCancelar(true);
                    },
                    fnCancel: null
                })
            }
        }).catch((err) => {

            setLoader(false);
            if (err.response.data.errors) {
                setError(err.response.data.errors);
            }

            if (err.response.data.error) {
                setErrorg(err.response.data.error);
            }
        });
    }


    const actualizarUsuarioJR = () => {
        setErrorg("");
        setError("");
        setLoader(true);

        let url = Constants.URL_BASE + Constants.WSACTULIZARJR;

        let detalleSucursal = [];

        Object.keys(listaSucursalesCategorias).forEach(element => {
            let sucursales = listaSucursalesCategorias[element].sucursales;
            let categorias = listaSucursalesCategorias[element].categorias;
            let id = listaSucursalesCategorias[element].id;

            Object.keys(categorias).forEach(categoria => {
                var codigoCate = categorias[categoria][0];
                Object.keys(sucursales).forEach(sucursal => {
                    var codigo = sucursales[sucursal];
                    detalleSucursal.push({
                        "CodigoSAC": codigo,
                        "CodigoCategoria": codigoCate + "",
                        "GrupoC": Number(id)
                    })
                })
            })
        })

        let data = {
            "nombres": nombre,
            "apellidos": apellido,
            "doc": Auth.getDoc(),
            "usuario": usuario,
            "telefono": celular,
            "email": email,
            "clave": contrasena,
            "sucursales": detalleSucursal
        }

        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            setLoader(false);
            if (response.data.message) {
                setAlerta({
                    tipo: "success",
                    titulo: "Usuario actulizado",
                    mensaje: response.data.message,
                    btnOk: "Aceptar",
                    fnOk: function () {
                        handleCancelar(true);
                    },
                    fnCancel: null
                })
            }
        }).catch((err) => {

            setLoader(false);
            if (err.response.data.errors) {
                setError(err.response.data.errors);
            }

            if (err.response.data.error) {
                setErrorg(err.response.data.error);
            }
        });
    }

    const getResponse = (response) => {
        if (response) {
            setSucursalesCategorias(Auth.getListaJR())
            setListaSucursales({})
            getSucursales(true);
        }
        setEditar([]);
        setModalSucursal(false)
    }



    const convertSucursales = (texto, item) => {
        let sucursalesTexto = "<ul>";
        {
            Object.keys(texto).forEach(element => {
                sucursalesTexto += "<li>" + listaSucursalesCategorias[item].sucursales[element] + "\n" + "</li>";
            })
        }
        sucursalesTexto += "</ul>";
        return sucursalesTexto;
    }

    const convertCategorias = (texto, item) => {
        let categoriasTexto = "<ul>";
        //let categoriasTexto ="";
        {
            Object.keys(texto).map((categoria, i) => {
                categoriasTexto += "<li>" + listaSucursalesCategorias[item].categorias[categoria][1] + "</li>";
                //categoriasTexto += listaSucursalesCategorias[item].categorias[categoria]+"</br>";
            })
        }
        categoriasTexto += "</ul>";
        return categoriasTexto;
    }

    const handleEditar = (item) => {
        getSucursales(false);
        setLoader(true);
        setTimeout(() => {
            var listaS = [];
            var posicion = 0;
            var posicionlista = 0;
            Object.keys(listaSucursalesCopia).forEach(lista => {
                var codigo = listaSucursalesCopia[lista].value;
                Object.keys(listaSucursalesCategorias[item].sucursales).forEach(element => {
                    var codigoA = listaSucursalesCategorias[item].sucursales[element];
                    if (codigo == codigoA) {
                        listaS[posicionlista++] = posicion;
                    }
                });
                posicion++;
            });
    
            var listaC = [];
            posicion = 0;
            posicionlista = 0;
            Object.keys(listaCategorias).forEach(lista => {
                var codigo = listaCategorias[lista].value;
                Object.keys(listaSucursalesCategorias[item].categorias).forEach(element => {
                    var codigoA = listaSucursalesCategorias[item].categorias[element][0];
                    if (codigo == codigoA) {
                        listaC[posicionlista++] = posicion;
                    }
                });
                posicion++;
            });
            setListaSucursales(listaSucursalesCopia);
            setEditar({
                editar: true,
                listaS: listaS,
                listaC: listaC,
                item: item
            })
            setModalSucursal(true);
            setLoader(false)
        }, 2500)
    }

    const handleEliminar = (item) => {
        var lista = Auth.getListaJR() || {};
        if (lista[item]) {
            delete lista[item];
            setSucursalesCategorias(lista);
            Auth.setListaJR(lista);
        }
    }



    return (
        <React.Fragment>
            {loader ? <Progress color={"#0194C5"}></Progress> :
                (<React.Fragment>

                    <div className="layout">
                        <div className="creacion">
                            <div className="datosjr-titulo">
                                <h2> Administrador de usuarios </h2>
                            </div>


                            <form className="crearjr-content" autoComplete="nope">

                                <div className="secciones">


                                    <div className="table-responsive">

                                        <table className="mytabla mytabla-responsive">
                                            <thead>
                                                <tr>
                                                    <th className="text-left">Id</th>
                                                    <th className="text-left">Sucursales</th>
                                                    <th className="text-left">Categoria</th>
                                                    <th className="text-left">Editar</th>
                                                    <th className="text-left">Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-hover">
                                                {Object.keys(listaSucursalesCategorias).map((item, i) => (
                                                    <tr key={i}>
                                                        <td className="text-left">{i}</td>
                                                        <td className="text-left" dangerouslySetInnerHTML={{ __html: convertSucursales(listaSucursalesCategorias[item].sucursales, item) }}></td>
                                                        <td className="text-left" dangerouslySetInnerHTML={{ __html: convertCategorias(listaSucursalesCategorias[item].categorias, item) }}></td>
                                                        <td className="text-left">
                                                            <a onClick={e => handleEditar(item)}>
                                                                <img src={detail} alt="icon delete" width="30px" height="30px"></img>
                                                            </a>
                                                        </td>
                                                        <td className="text-left">
                                                            <a onClick={e => handleEliminar(listaSucursalesCategorias[item].id)}>
                                                                <img src={eliminar} alt="icon delete" width="30px" height="30px"></img>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    {loaderB ? null : (<a className="table-title" onClick={() => handleAddUser()}>Agregar Sucursal </a>)}

                                </div>

                                <div className="registro">
                                    <div className="form-control">
                                        <label>Usuario:</label>
                                        <input type="text" disabled={"disabled"} defaultValue={usuario}></input>
                                    </div>

                                    <div className="form-control">
                                        <label>Nombre:</label>
                                        <input type="text" placeholder="Nombre" value={nombre} onChange={e => setNombre(e.target.value)}></input>
                                        {error.Nombres ? (
                                            <span className="error">
                                                <b>{error.Nombres}</b>
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <label>Apellido:</label>
                                        <input type="text" placeholder="Apellido" value={apellido} onChange={e => setApellido(e.target.value)}></input>
                                        {error.Apellidos ? (
                                            <span className="error">
                                                <b>{error.Apellidos}</b>
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <label>Celular:</label>
                                        <input type="number" placeholder="Celular" value={celular} onChange={e => setCelular(e.target.value)}></input>
                                    </div>
                                    <div className="form-control">
                                        <label>Correo:</label>
                                        <input type="email" placeholder="Dirección de correo electronico" value={email} onChange={e => setEmail(e.target.value)}></input>
                                        {error.Email ? (
                                            <span className="error">
                                                <b>{error.Email}</b>
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="form-control">
                                        <label>Contraseña:</label>
                                        <input type="password" placeholder="Contraseña" value={contrasena} onChange={e => setContrasena(e.target.value)}></input>
                                        {error.Clave ? (
                                            <span className="error">
                                                <b>{error.Clave}</b>
                                            </span>
                                        ) : null}
                                    </div>

                                    {errorg ? (
                                        <span className="error">
                                            <b>* {errorg}</b>
                                        </span>
                                    ) : null}

                                    {editarUsuario.editar ? (
                                        <button type="button" className="btn-large" onClick={() => actualizarUsuarioJR()}>Actualizar</button>
                                    )
                                        : (<button type="button" className="btn-large" onClick={() => registrarUsuarioJR()}>Registrar</button>)
                                    }
                                    <button type="button" className="btn-large outline" onClick={() => handleCancelar(false)}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </React.Fragment>
                )
            }
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            {modalSucursal ? (<ModalUserSucursales sucursales={listaSucursales} categorias={listaCategorias} editar={editar} callback={getResponse.bind(this)} />) : null}
        </React.Fragment>
    )
}


export default DatosCrearUsuarioJR;
