import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';

import Progress from '../util/Progress';
import AlertModal from '../modal/AlertModal';
import CardProducto from '../util/CardProducto';

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {selectActiveUser} from '../../store/usuario/reducer';


const Producto = (props) => {

    const [alerta, setAlerta] = useState();
    const [loader, setLoader] = React.useState(true);
    const [productos, setProductos] = useState([]);

    const loadValidacion = () => {
        var url = '';
        // var cliente = props.usuario;
        // if(cliente.fechaMayorEdad){
        //     url += "&mayor=true";
        // }else{
        //     url += "&mayor=false";
        // }

        // if(cliente.fechaMayor14){
        //     url += "&catorce=true";
        // }else{
        //     url += "&catorce=false";
        // }
        url += "&mayor=true";
        url += "&catorce=true";

        return url;
    }

    // TRAE TODOS LOS PRODUCTOS
    const loadProductos = async (tipo) => {
        var sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSPRODUCTOS;
        
        if(props.search){
            url += "?buscar=" + props.search;
            url += loadValidacion();
        } else if(props.categoriaId){
            url += "?categoriaId=" + props.categoriaId;
            url += loadValidacion();
        }

        if(Object.keys(sucursal).length > 0){
            var ref = url.split("?");
            if (ref.length > 0) {
                url += "&codLista=" + sucursal.codListaP+"&CodigoCliente="+sucursal.codigoSac+"&usuarioL="+Auth.getDoc()+"&tipoUsuario="+Auth.getTipoUsuario();
            }else{
                url += "?codLista=" + sucursal.codListaP+"&CodigoCliente="+sucursal.codigoSac+"&usuarioL="+Auth.getDoc()+"&tipoUsuario="+Auth.getTipoUsuario();
            }
        }
        if(tipo == 1){
            if(productos){
                if(productos.now < productos.next){
                    url += "?page=" + productos.next;
                }
            }
        }
        console.log("*** "+url);
        axios.get(url)
        .then(res => {
            setLoader(false);
            setProductos(res.data);
        })
        .catch(err => {
            setLoader(false);
        });
    }

    const cargarMas = () => {
        loadProductos(1);
    }

    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {
        setLoader(true);
        if(props.categoriaId){
            loadProductos(0);
        }
    }, [props.categoriaId, props.search])

    // VISTA
    return (
        <div className="pedido-list">
            { loader ?<Progress color={"#0194C5"}></Progress>: (
                <React.Fragment>
                    {productos.length > 0 ? (
                        <React.Fragment>
                            <h1 className="titulo-lista"><b>Selecciona los productos que deseas.</b></h1>
                        </React.Fragment> 
                    ) : (
                        <h2 className="titulo-lista"><b>No se encontraron productos</b></h2>
                    )}
                    
                    {productos.length > 0 ? (
                        <React.Fragment>
                            { props.tipo == 2 ? (
                                null                                
                            ) : (
                                <div className="product-card">
                                    {productos.map((item, i) => (
                                        <CardProducto 
                                            key={i}
                                            producto={item}
                                            setAlerta={setAlerta}/>
                                    ))}
                                </div>
                            )}

                            <div className="load-mas">
                                {productos.now < productos.next ? (
                                    <button onClick={() => cargarMas()}>Cargar mas</button>
                                ) : null }
                            </div>
                            
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            )}
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarCarrito}) (Producto));