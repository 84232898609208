import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Constants from '../../constants/global';
import axios from 'axios';

import Auth from '../../helpers/Auth';

import address_blue from '../../assets/icon/documents.png';
import AlertShow from '../../store/alerta/action';
import { selectActiveAlert } from '../../store/alerta/reducer';
import Progress from '../util/Progress';
import certificado from '../../assets/img/certicadoComercial.png';
import jsPDF from 'jspdf';


const ReferenciaComercial = (props) => {

    const [error, setError] = useState({});
    const [cargando, setCargando] = useState(false);
    const [sucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState("NONE");
    const [login, setLogin] = useState(false);
    const [errors, setErrors] = React.useState("");
    const [codigo, setCodigo] = React.useState('');


    const handleChangeSuc = (item) => {
        setSucursal(item);
        setError('');
    }

    const handleClose = () => {
        if (login) {
            props.showModal(false);
        } else {
            props.AlertShow(false);
        }
    }

    const handleGenerarDocumento = () => {
        var err = {};
        const sucu = JSON.parse(sucursal);
        if (sucursal == "NONE") {
            err['Sucursal'] = "Seleccione la sucursal para generar el documento.";
            setError(err);
        } else {
            setError('');
        }

        setCargando(true);

        const url = Constants.URL_BASE + Constants.WSCERTIFICADO

        let data = {
            "codigoCliente": sucu.codigoSac,
            "nombre": sucu.nombre,
            "razonSocial": sucu.razonSocial,
            "nit": sucu.nit,
            "direccion": sucu.direccion,
            "barrio": sucu.barrio,
            "fechaIngreso": sucu.fechaIngreso,
            "cupo": sucu.cupo,
            "tipoCredito": sucu.descCredito
        }

        axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            exportPDF(sucu, response.data.codigoCertificado);
        }).catch((err) => {
            setCargando(false);
            if (err.response.data.errors) {
                err['Sucursal'] = err.response.data.errors.Cupo[0];
                setError(err);
            }
        });
    }

    const exportPDF = (sucu, codigoCertificado) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const tipoLetra = "Helvetica";
        const margenDerecho = 80; // margen
        const fecha = new Date().toLocaleString('es-CO', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }) + '';

        const doc = new jsPDF(orientation, unit, size);

        doc.addImage(certificado, "PNG", 0, 0, 595.28, 841.89)

        doc.setFontSize(10);
        doc.setFont(tipoLetra, "normal");
        doc.text("Bogotá, " + fecha, margenDerecho, 120);


        doc.text("Señor (es)", margenDerecho, 170);
        doc.setFont(tipoLetra, "bold");
        doc.text("A QUIEN INTERESE", margenDerecho, 185);
        doc.setFont(tipoLetra, "normal");
        doc.text(sucu.ciudad, margenDerecho, 200);

        doc.text("Conforme a su solicitud damos Referencia Comercial del siguiente cliente", margenDerecho, 230);


        doc.text("NOMBRE:         " , margenDerecho, 260);
        doc.text("RAZON SOCIAL:   " , margenDerecho, 275);
        doc.text("NIT ó CEDULA:   " , margenDerecho, 290);
        doc.text("DIRECCION:      " , margenDerecho, 305);
        doc.text("BARRIO:         " , margenDerecho, 320);

        doc.text(sucu.nombre, 165, 260);
        doc.text(sucu.razonSocial, 165, 275);
        doc.text(sucu.nit, 165, 290);
        doc.text(sucu.direccion, 165, 305);
        doc.text(sucu.barrio, 165, 320);


        doc.text("Los datos que referenciamos son los siguientes:", margenDerecho, 350);

        doc.text("Cliente desde:         ", margenDerecho, 380);
        doc.text("Cupo de crédito:       ", margenDerecho, 395);
        doc.text("Condiciones de Ventas: ", margenDerecho, 410);

        doc.text(sucu.fechaIngreso, 190, 380);
        doc.text(Intl.NumberFormat().format(sucu.cupo), 190, 395);
        doc.setFont(tipoLetra, "bold");
        doc.text(sucu.descCredito, 190, 410);

        doc.setFont(tipoLetra, "normal");
        doc.text("Observaciones o comentarios: ", margenDerecho, 440);


        doc.text("El cliente cumple con las condiciones de pago definidas y distribuye los productos de la \ncompañía.", margenDerecho, 480);

        doc.text("Los anteriores datos son expresamente informativos y no comprometen a nuestra \norganización en la toma de decisiones de Terceros y uso posterior del mismo.", margenDerecho, 530);

        doc.setFont(tipoLetra, "bold");
        doc.text("Para confirmar la información dirigirse a tiendavirtual.casaluker.com botón de \nconfirmación y digite el siguiente número de comprobación", margenDerecho, 580);

        doc.setTextColor(0, 0, 255);
        doc.text(codigoCertificado + ".", 368, 592);

        doc.setTextColor(0, 0, 0);
        doc.setFont(tipoLetra, "normal");
        doc.text("Cordialmente,", margenDerecho, 630);

        doc.setFont(tipoLetra, "bold");
        doc.text("Departamento de Cartera", margenDerecho, 670);
        doc.text("CasaLuker S.A.", margenDerecho, 685);
        doc.text("Nit: 890.800.718-1", margenDerecho, 700);

        doc.save("CertificadoComercial.pdf")

        setTimeout(function () {
            setCargando(false);
            handleClose();
        }, 2000);

    }


    const handleSubmit = () => {
        if (codigo.length <= 0) {
            setErrors('Ingrese el codigo de referencia para descargar el docuemnto.');
        } else {
            setErrors('');
        }

        setCargando(true);
        let url = Constants.URL_BASE + Constants.WSCERTIFICADOCODIGO + "?codigo=" + codigo;

        axios.get(url).then(res => {
            let data = res.data || [];
            console.log(res);
            if (res.data.length == 0) {
                setErrors('El codigo de referencia no existe, por favor valide.');
            } else {
                exportPDF(data, data.codigoCertificado);
            }
        })

    }


    const handleRedirect = item => {
        props.history.push(item);
    }


    const getSucursales = () => {
        setCargando(true);
        let url = Constants.URL_BASE + Constants.WSSUCURSALESCERTIFICADO + "?usuario=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();

        axios.get(url, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }
        ).then(res => {
            let data = res.data || [];
            setSucursales(data);
            setCargando(false);
        })
    }

    useEffect(() => {
        document.querySelector(".modal-cobertura").classList.toggle("show-modal");
        if (props.login) {
            setLogin(props.login)
        } else {
            getSucursales();
        }
    }, [])

    return (
        <React.Fragment>
            <div className="modal modal-cobertura">
                <div className="modal-content table-content">
                    <div className="content">
                        <div className="alert-content">
                            <div className="content-img">
                                <img src={address_blue} alt="icon menu"></img>
                            </div>
                            {login ? <p className="alert-title">Confirmacion Referencia Comercial</p> : <p className="alert-title">Referencia Comercial</p>}
                            {login ? null: <p className="alert-message">Por favor seleccione la sucursal para generar el documento.</p>}
                            
                        </div>

                        <form >
                            {cargando ? <Progress color={"#0194C5"}></Progress> :
                                <>
                                    {login ?
                                        <div className="form-control" >
                                            <label>Codigo referencia comercial<span>*</span>:</label>
                                            <input
                                                className={errors ? "input-error" : null}
                                                type="number"
                                                placeholder="Codigo referencia comercial"
                                                name="codigo"
                                                id="codigo"
                                                onChange={e => setCodigo(e.target.value)}
                                                value={codigo}>
                                            </input>
                                            {errors ? (
                                                <span className="error">
                                                    <b>{errors}</b>
                                                </span>
                                            ) : null}

                                            <button type="button" className="btn-large" onClick={e => handleSubmit()}>Generar Documento</button>

                                        </div>
                                        :

                                        <>
                                            <div className="form-control" onSubmit={handleSubmit}>
                                                <label>Sucursales <span>*</span>:</label>
                                                <select
                                                    className={error.Sucursal ? "input-error" : null}
                                                    name="sucursales"
                                                    value={sucursal}
                                                    onChange={e => handleChangeSuc(e.target.value)}>
                                                    <option selected="true" value="NONE">Seleccione sucursal</option>
                                                    {sucursales.map((item, i) => (
                                                        <option key={i} value={JSON.stringify(item)}> {item.codigoSac + " - " + item.direccion} </option>
                                                    ))}
                                                </select>
                                                {error.Sucursal ? (
                                                    <span className="error">
                                                        <b>{error.Sucursal}</b>
                                                    </span>
                                                ) : null}
                                            </div>


                                            <button type="button" className="btn-large" onClick={e => handleGenerarDocumento()}>Generar Documento</button>
                                        </>
                                    }
                                </>
                            }


                            <button type="button" className="btn-large outline" onClick={e => handleClose()}>Cancelar</button>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToprops = (state) => {
    return {
        comprobante: selectActiveAlert(state)
    }
}

//export default Menu;
export default withRouter(connect(mapStateToprops, { AlertShow })(ReferenciaComercial));

