import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Maqueta from '../maqueta/Maqueta';
import DireccionPlaces from '../util/DireccionPlaces';
import Progress from '../util/Progress';
import PoliticaModal from '../modal/PoliticaModal';
import AlertModal from '../modal/AlertModal';

const NoCobertura = (props) => {

    const [alerta, setAlerta] = useState();
    const [departamentos=[], setDepartamentos] = useState();
    const [municipios=[], setMunicipios] = useState();

    const [departamento, setDepartamento] = useState();
    const [municipio, setMunicipio] = useState();

    const [politica, setPolitica] = useState(0);
    // const [barNoCob, setBarNoCob] = useState();
    const [dirNoCob, setDirNoCob] = useState();
    const [refNoCob, setRefNoCob] = useState();
    const [barrio, setBarrio] = useState();
    const [complemento, setComplemento] = useState();
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const [telefono, setTelefono] = useState();
    const [correo, setCorreo] = useState();
    const [terminos, setTerminos] = useState(); 
    const [habeas, setHabeas] = useState(); 
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [errorg, setErrorg] = useState("");
    
    let data= {departamento:null,  municipio:null, barrio:null,   direccion:''  }


    setTimeout(function(){
        if(document.querySelector(".registro-content")){
            document.querySelector(".registro-content").classList.add("show");
        }
    }, 3000);

    const handleChangeDep = (item) => {
        if(item){
            var depto = JSON.parse(item);
            setDepartamento(item)
            setMunicipio(null);
            data.departamento=depto.id;
            getMunicipios()
            setDirNoCob("");
            setBarrio("");
        }
    }

    const handleChangeMun = (item) =>{
        if(item){
            var muni = JSON.parse(item);
            setMunicipio(item)
            data.municipio=muni.id
            setDirNoCob("");
            setBarrio("");
        }
    }

    const getMunicipios = () =>{
        const municipiosData = () => {
            let url = Constants.URL_BASE + Constants.WSMUNICIPIO + data.departamento + "?co=0"
            axios.get(url).then(res=>{
                let data = res.data || [];
                setMunicipios(data);
            })
        };
        municipiosData();
    }

    const getDepartamentos = () =>{
        const departamentoData = () => {
            let url = Constants.URL_BASE + Constants.WSDEPARTAMENTOS + "?co=0" 
            axios.get(url).then(res=>{
                let data = res.data || [];
                setDepartamentos(data);
            })
        };
        departamentoData();
    }

    const handlePolitica = item => {
        setPolitica(0);
    }



    useEffect(() => {
        getDepartamentos()
    }, [])

    const handleSolicitud = () => {
        setErrorg("");
        setError("");

        var err = {};
        if(!departamento){
            err['Departamento'] = "El departamento es obligatorio";
            setError(err);
        }

        if(!municipio) {
            err['Municipio'] = "El municipio es obligatorio";
            setError(err);
        }

        if(!dirNoCob) {
            err['Direccion'] = "la direccion es obligatoria.";
            setError(err);
        }

        if(!barrio) {
            err['Barrio'] = "El barrio es requerido.";
            setError(err);
        }

        if(Object.keys(err).length == 0) {
            setRefNoCob(barrio + " - " + complemento);
            var depto = JSON.parse(departamento)
            var muni = JSON.parse(municipio)
            setLoader(true)
            let data= {
                'departamentoId': Number(depto.id),
                'municipioId': Number(muni.id),
                'barrio': "0",
                'direccion': dirNoCob,
                'referencia': barrio + " - " + complemento,
                'latitud': latitud,
                'longitud': longitud,
                'telefono': telefono,
                'tyC':  Number(terminos) || 0,
                'habeasData': Number(habeas) || 0,
                'email': correo
            }
            axios.post(Constants.URL_BASE + Constants.WSNOCOBERTURA, data).then((response)=>{
                setLoader(false)
                setAlerta({
                    tipo: "success",
                    titulo: "No cobertura.",
                    mensaje: response.data.message,
                    btnOk: "Aceptar",
                    fnOk: function(){
                        props.history.push('/login');
                    },
                    fnCancel: null
                })   
                
            }).catch((err)=>{
                setLoader(false);            
                if(err.response.data.errors){
                    setError(err.response.data.errors);
                }

                if(err.response.data.error){
                    setErrorg("El correo es obligatorio");
                }

                if(terminos){
                    document.getElementById('terminos_condiciones').checked=true  
                }else{
                    setTerminos();
                    setHabeas();
                }
            })
        }
        
        
       
    }

    const handleCheckbox = (check) =>{
        var checkbox = document.getElementById(check);
        checkbox.addEventListener("change", validaCheckbox, false);
        
        function validaCheckbox(){
          var checked = checkbox.checked;
          if(checked){
            if(check=="terminos_condiciones"){
                setTerminos(1);
                setHabeas(1);
            }
          }else{
            if(check=="terminos_condiciones"){
                setTerminos();
                setHabeas();
            }
          }
        }
    }

    const handleInput = (id_input) => {

        const input = document.getElementById(id_input);

        if(input){

            input.addEventListener('keydown', function(evento) {
                const elemento = evento.target;
                const teclaPresionada = evento.key;
                const teclaPresionadaEsUnNumero =
                Number.isInteger(parseInt(teclaPresionada));

                const sePresionoUnaTeclaNoAdmitida = 
                teclaPresionada !== 'ArrowDown' &&
                teclaPresionada !== 'ArrowUp' &&
                teclaPresionada !==  'ArrowLeft' &&
                teclaPresionada !== 'ArrowRight' &&
                teclaPresionada !== 'Backspace' &&
                teclaPresionada !== 'Delete' &&
                teclaPresionada !== 'Enter' &&
                teclaPresionada !== 'Tab' &&
                !teclaPresionadaEsUnNumero;
                const comienzaPorCero = 
                elemento.value.length == 0 &&
                teclaPresionada == 0;

                if (sePresionoUnaTeclaNoAdmitida || comienzaPorCero) {
                    evento.preventDefault(); 
                }
                
            });
        }
    }

    useEffect(() => {
        handleInput("telefono");
    }, [])

    return (
        <React.Fragment>
            <div className="login">
                <div className="login-content">
                    <Maqueta />
                    <div className="login-form" id="login-form">
                        <div className="main">
                            <label className="large">Solicitud de cobertura</label>   
                            <form>

                                {loader ? <Progress color={"#0194C5"}></Progress> : (
                                    <React.Fragment>
                                        <div className="form-control">
                                            <label>Departamento <span>*</span>:</label>
                                            <select 
                                            className={error.Departamento ? "input-error" : null}
                                            name="departamentos" 
                                            value={departamento} 
                                            onChange={e => handleChangeDep(e.target.value)}>
                                                <option>Seleccione departamento</option>
                                                {departamentos.map((item,i)=>(
                                                    <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                ))}
                                            </select> 
                                            { error.Departamento ? (
                                                <span className="error">
                                                    <b>{error.Departamento}</b>
                                                </span>
                                            ) : null }
                                        </div>
                                       
                                        <div className="form-control"> 
                                            <label>Municipio <span>*</span>:</label>
                                            <select 
                                            className={error.Municipio ? "input-error" : null}
                                            name="municipios" 
                                            value={municipio} 
                                            onChange={e => handleChangeMun(e.target.value)}>
                                                <option>Seleccione un municipio</option>
                                                {municipios.map((item,i)=>(
                                                    <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                ))}
                                            </select> 
                                            { error.Municipio ? (
                                                <span className="error">
                                                    <b>{ error.Municipio }</b>
                                                </span>
                                            ) : null }
                                        </div>
                                       
                                        
                                        { municipio ? (
                                            <React.Fragment>
                                                <DireccionPlaces 
                                                    departamento={departamento}
                                                    municipio={municipio}
                                                    direccion={dirNoCob}
                                                    barrio={barrio}
                                                    complemento={complemento}
                                                    updateDireccion={setDirNoCob}
                                                    updateBarrio={setBarrio}
                                                    updateComplemento={setComplemento}
                                                    updateLatitud={setLatitud}
                                                    updateLongitud={setLongitud}
                                                    error={error}/>
                                                <div className="form-control">
                                                    <label>Teléfono:</label>
                                                    <input 
                                                        type="number" 
                                                        min="1" 
                                                        step="1" 
                                                        id="telefono"
                                                        name="telefono"
                                                        placeholder="Teléfono celular o fijo" 
                                                        value={telefono} 
                                                        onChange={e => setTelefono(e.target.value) } 
                                                        required></input>
                                                </div>
                                                <div className="form-control">
                                                    <label>Correo:</label>
                                                    <input 
                                                    className={error.Email ? "input-error" : null}
                                                    type="email" 
                                                    placeholder="Dirección de correo electrónico" 
                                                    value={correo} 
                                                    onChange={e => setCorreo(e.target.value) } required></input>
                                                    { error.Email ? (
                                                        <span className="error">
                                                            <b>{error.Email}</b>
                                                        </span>
                                                    ) : null }
                                                </div>
                                                
                                                <div className="form-check">
                                                    <input type="checkbox" defaultValue="true" id="terminos_condiciones" name="terminos_condiciones" onClick={e => handleCheckbox('terminos_condiciones')}></input>
                                                    <label htmlFor="terminos_condiciones">
                                                        <span></span>
                                                        Acepto <a onClick={() => setPolitica(2)}>solicitud de autorización de datos personales</a> y <a onClick={() => setPolitica(1)}>términos y condiciones</a> 
                                                    </label>
                                                </div>
                                            </React.Fragment>
                                        ) : null }
                                      
                                        { error.TyC ? (
                                            <span className="error">
                                                <b>* Acepte autorización de datos personales y términos y condiciones</b>
                                            </span>
                                        ) : null}

                                        { errorg ? (
                                            <span className="error">
                                                <b>* {errorg}</b>
                                            </span>
                                        ) : null }

                                        <button type="button" className="btn-large" onClick={e => handleSolicitud()}>Enviar solicitud</button>
                                        <button type="button" className="btn-large outline" onClick={e => props.history.push('/Cobertura')}>Cancelar</button>
                                        {politica > 0 ? (<PoliticaModal politica={politica} handlePolitica={handlePolitica}/>): (null)}
                                    </React.Fragment>
                                )}
                          
                            </form>
                        </div>
                    </div>
                </div>
            </div> 
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}           
        </React.Fragment>
    )
}

export default withRouter(NoCobertura);