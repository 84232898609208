import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Constants from '../../constants/global';
import Progress from '../util/Progress';

import Navbar from '../navbar/Navbar';
import Sub_nav from '../navbar/Sub_nav';
import Footer from '../footer/Footer';

import Auth from '../../helpers/Auth';
import AlertModal from '../modal/AlertModal';
import TabletC from "../util/TabletC";
import Moment from 'moment';
import InnerHTML from 'dangerously-set-html-content'

const Cartera = (props) => {

  const [alerta, setAlerta] = useState();
  const [cantidad, setCantidad] = useState(0);
  const [loader, setLoader] = useState(false);
  const [cartera, setCartera] = useState([]);
  const [carteraPago, setCarteraPago] = useState([]);
  const [total, setTotal] = useState(0);
  const [numDoc, setNumDoc] = useState("");
  const [sucursal, setSucursal] = useState('');

  const getCantidad = () => {
    var cant = 0;
    let cart = JSON.parse(localStorage.getItem('carrito')) || {};
    Object.keys(cart).map((item, i) => {
      cant += cart[item].cantidad;
    })
    setCantidad(cant);
  }


  useEffect(() => {
    getCantidad();
    getDetalle();
  }, [props.usuario])


  const getDetalle = () => {
    var sucursal = Auth.getSucursal();
    setSucursal(sucursal);
    setLoader(true);
    let url = Constants.URL_BASE + Constants.WSCARTERA + "?clienteERP=" + sucursal.codigoSac + "&pagoCartera=true";;
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((response) => {
      setCartera(response.data)
      setLoader(false);
    });
  }


  const handlePayment = () => {
    setNumDoc("BC" + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds())
    let detalle = [];

    carteraPago.map((item, i) => {
      detalle.push({
        "Documento": item.documento,
        "NroFactura": item.referencia,
        "ValorDoc": item.saldo,
        "ValorPagado": item.saldoPago,
        "TipoPago": item.saldo == item.saldoPago ? "T" : "P",
        "TipoDoc": item.causaDev
      })
    })

    let encabezado = {
      NroDoc: numDoc,
      CodigoCliente: sucursal.codigoSac,
      Total: total,
      detalle: detalle
    }

    enviarago(encabezado)
  }


  const enviarago = (carteraP) => {
    Auth.setCartera(numDoc);
    axios.post(Constants.URL_BASE + Constants.WSCARTERA + "/pago", carteraP, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`
      }
    }).then((response) => {
      getDetalle();
    }).catch((err) => {
      console.log(err);
    })
  }

  const handleTotal = (data) => {
    console.log(data);
    setCarteraPago(data);
    let totalP = 0;
    data.forEach((element) => {
      totalP = totalP + element.saldoPago;
    })

    setNumDoc("BC" + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds())
    setTotal(totalP);
  }
  
  const handlePago = () => {
      var checkout = new window.WidgetCheckout({
        currency: 'COP',
        amountInCents: (total * 100).toFixed(0),
        reference: numDoc,
        publicKey: process.env.REACT_APP_B2B,
        redirectUrl: process.env.REACT_APP_URL_B2B
      });

      handlePayment();

      checkout.open(function ( result ) {
        var transaction = result.transaction
        window.location.reload(true);
        console.log('Transaction ID: ', transaction.id)
        console.log('Transaction object: ', transaction)
      })
}


  return (
    <React.Fragment>

      <div className="navbar navcarrito">
        <Navbar item={3} />
        <Sub_nav cantidad={cantidad} />
      </div>
      <div className="layout">
        <div className="cartera">
          {cartera ? (
            <React.Fragment>
              <div className="table-title">
                <h3>Estado Cartera</h3>
              </div>

              {
                loader ? <Progress color={"#0194C5"}></Progress> :
                  <>
                    <TabletC updateTotal={handleTotal} data={cartera} updateAlerta={setAlerta}/>
                    <br />
                    <br />
                    <div className="total">
                      <div className="total-content">
                        <div>
                          <div className="item">
                            <p className="opc">Total:</p>
                            <p className="val">
                              {" $" + new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                maximumFractionDigits: 0,
                                currency: "COP"
                              }).format((total))}
                            </p>
                          </div>
                        </div>
                        <button type="button" className="btn-large" onClick={e => handlePago()}>Realizar pago</button> 
                        {/* <InnerHTML html={html} onClick={e => handlePayment()} /> */}
                      </div>
                    </div>
                  </>
              }
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
      <Footer />
    </React.Fragment>
  )
}


export default Cartera;
