import React, { useState, useEffect } from 'react';
import close from '../../assets/icon/close_grey.svg';
import AlertModal from './AlertModal';
import Auth from '../../helpers/Auth';
import Select from 'react-select';


const ModalUserSucursales = (props) => {

    const [alerta, setAlerta] = useState();
    const [error, setError] = useState("");
    const [sucursales, setSucursales] = useState([]);
    const [sucursalesSelect, setSucursalesSelect] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [categoriasSelect, setCategoriasSelect] = useState([]);
    const [sucursalesSelecciondas, setSucursalesSelecciondas] = useState([]);
    const [categoriasSelecciondas, setCategoriasSelecciondas] = useState([]);
    const [listaSucursalesCategorias, setlistaSucursalesCategorias] = useState([]);
    const [position, setPosition] = useState();
    const [editar, setEditar] = useState([]);
    const [agregarS, setAgregarS] = useState(false);
    const [agregarC, setAgregarC] = useState(false)

    const handleClose = (state) => {
        if (state) {
            var err = {};

            if (!agregarS) {
                err['Sucursal'] = "Por favor agregue al menos 1 sucursal es obligatoria.";
                setError(err);
                return;
            } else if (!agregarC) {
                err['Categoria'] = "Por favor agregue al menos 1 categoria es obligatoria.";
                setError(err);
                return;
            }
            handleAggPosition();
        }

        props.callback(state)
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }


    useEffect(() => {
        let ahora = new Date();
        let milisegundos = ahora.getMilliseconds();
        setSucursales(props.sucursales);
        setCategorias(props.categorias);
        setEditar(props.editar);
        setPosition(Object.keys(Auth.getListaJR()).length + 1 + milisegundos);

        handleEdit();

        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
    }, [props.usuario])

    const handleEdit = () => {
        let list = [];
        let listCat = [];
        if (props.editar.item) {
            setPosition(props.editar.item);
        }


        if (props.editar.listaS != null) {
            Object.keys(props.editar.listaS).map((item, i) => (
                list[i] = props.sucursales[props.editar.listaS[item]]
            ))
        } else {
            list = sucursales.selectedOption;
        }

        if (props.editar.listaC != null) {
            Object.keys(props.editar.listaC).map((item, i) => (
                listCat[i] = props.categorias[props.editar.listaC[item]]
            ))
        } else {
            listCat = categorias.selectedOption;
        }

        if (props.editar.editar) {
            setAgregarC(true);
            setAgregarS(true);
        }

        setSucursalesSelect(list);
        setCategoriasSelect(listCat);
    }

    const handleChangeSucursales = (selectedOption) => {
        if (selectedOption != null) {
            let value = Array.from(selectedOption, option => option.value);
            setSucursalesSelecciondas(value);
            setSucursalesSelect(selectedOption);
            setAgregarS(true);
        } else {
            setSucursalesSelecciondas(null);
            setSucursalesSelect(null);
            setAgregarS(false);
        }
    };

    const handleChangeCategorias = (selectedOption) => {
        if (selectedOption != null) {
            let value = Array.from(selectedOption, option => [option.value, option.label]);
            setCategoriasSelecciondas(value);
            setCategoriasSelect(selectedOption);
            setAgregarC(true);
        } else {
            setCategoriasSelecciondas(null);
            setCategoriasSelect(null);
            setAgregarC(false);
        }
    };

    const handleAggPosition = () => {
        if (editar.editar) {
            console.log(editar.editar +"&&"+ categoriasSelecciondas.length +"=="+ Auth.getListaJR()[position].categorias.length +"&&"+ sucursalesSelecciondas.length +"=="+ Auth.getListaJR()[position].sucursales.length);
        }

        if (editar.editar && categoriasSelecciondas.length == 0 && sucursalesSelecciondas.length == 0) {
            console.log("return");
            return;
        }

        var seleccionadas = Auth.getListaJR() || {};
        if (editar.editar && ( categoriasSelecciondas.length == 0 || sucursalesSelecciondas.length == 0)) {
            if (sucursalesSelecciondas.length == 0) {
                seleccionadas[position] = {
                    id: position,
                    sucursales: Auth.getListaJR()[position].sucursales,
                    categorias: categoriasSelecciondas
                }
            } else if (categoriasSelecciondas.length == 0) {
                seleccionadas[position] = {
                    id: position,
                    sucursales: sucursalesSelecciondas,
                    categorias: Auth.getListaJR()[position].categorias
                }
            }
        } else {
            seleccionadas[position] = {
                id: position,
                sucursales: sucursalesSelecciondas,
                categorias: categoriasSelecciondas
            }
        }
        Auth.setListaJR(seleccionadas);
    };


    return (
        <React.Fragment>
            <div className="update">
                <div className="update-content">
                    <div className="update-form" id="update-form">
                        <form>
                            <div className="form-control">
                                <div className="modal modal-sucursal">
                                    <div className="modal-content create-content">
                                        <div className="title">
                                            <p>Agregar Sucursal</p>

                                            <span className="close-button" onClick={() => handleClose(false)}>
                                                <img
                                                    src={close}
                                                    width="15"
                                                    height="15"
                                                    alt="icon close" />
                                            </span>
                                        </div>
                                        <div className="content">
                                            <form>
                                                <div className="form-control">


                                                    {sucursales ? <React.Fragment>
                                                        <div className="form-control">
                                                            <label>Sucursales:</label>
                                                            <Select
                                                                isMulti
                                                                onChange={handleChangeSucursales}
                                                                value={sucursalesSelect}
                                                                defaultValue={sucursalesSelect}
                                                                options={sucursales}
                                                                maxMenuHeight={350}
                                                                menuPlacement={'bottom'}
                                                                placeholder="Seleccione las sucursales"
                                                                closeMenuOnSelect={false}
                                                            />
                                                            {error.Sucursal ? (
                                                                <span className="error">
                                                                    <b>{error.Sucursal}</b>
                                                                </span>
                                                            ) : null}
                                                        </div>

                                                    </React.Fragment> : null}

                                                    {categorias ? <React.Fragment>
                                                        <div className="form-control">
                                                            <label>Categorias:</label>
                                                            <Select
                                                                isMulti
                                                                options={categorias}
                                                                vaue
                                                                onChange={handleChangeCategorias}
                                                                value={categoriasSelect}
                                                                defaultValue={categoriasSelect}
                                                                maxMenuHeight={150}
                                                                menuPlacement={'bottom'}
                                                                placeholder="Seleccione las categorias"
                                                                closeMenuOnSelect={false}
                                                            />
                                                            {error.Categoria ? (
                                                                <span className="error">
                                                                    <b>{error.Categoria}</b>
                                                                </span>
                                                            ) : null}
                                                        </div>

                                                    </React.Fragment> : null}

                                                    <button type="button" className="btn-large" onClick={() => handleClose(true)}>Agregar</button>
                                                    <button type="button" className="btn-large outline" onClick={() => handleClose(false)}>Cancelar</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
        </React.Fragment>
    )
}



export default ModalUserSucursales;
