import React, { useState } from 'react';

const Autocomplete = (props) => {

  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  //const [userInput, setUserInput] = useState("");

  const onChange = e => {
      const userInput = e.currentTarget.value;
  
      const arrayFilter = props.suggestions.filter(function (el) {
        return el.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      });

      setActiveSuggestion(0);
      setFilteredSuggestions(arrayFilter.slice(0, 5));
      setShowSuggestions(true);
      props.updateBarrio(e.currentTarget.value);
  };

  const onClick = e => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    props.updateBarrio(e.currentTarget.innerText);
  };

  const onKeyDown = e => {
    if (e.keyCode == 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      props.updateBarrio(filteredSuggestions[activeSuggestion].name);
    }

    else if (e.keyCode == 38) {
      if (activeSuggestion == 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }

    else if (e.keyCode == 40) {
      if (activeSuggestion + 1 == filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };
  
  return (
    <React.Fragment>
      <input
        className={props.error ? "autocomplete input-error" : "autocomplete"}
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={props.barrio}
      />
      { showSuggestions && props.barrio ? (
        <React.Fragment>
          { filteredSuggestions.length ? (
            <ul class="suggestions">
              {filteredSuggestions.map((suggestion, i) => (
                <li
                  className={i == activeSuggestion ? 'suggestion-active' : null}
                  onClick={onClick}
                  key={suggestion.id}>
                    {suggestion.name}
                </li>
              ))}
              
            </ul>
          ) : (
            <div class="no-suggestions">
              <em>Barrio no existe</em>
            </div>
          ) }
        </React.Fragment>
      ) : null }
     
    </React.Fragment>
  )
}

export default Autocomplete;