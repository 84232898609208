import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import axios from 'axios';
import * as Constants from './constants/global';
import Auth from './helpers/Auth';

// COMPONENTS
import Login from './components/auth/Login';
import Catalogo from './components/pedido/Catalogo';
import Detalle from './components/pedido/Detalle';
import Carrito from './components/pedido/Carrito';
import Seccion from './components/pedido/Seccion';
import Pack from './components/pedido/Pack';
import Cobertura from './components/auth/Cobertura';
import Registro from './components/auth/Registro';
import {RegistroNuevo}  from './components/auth/RegistroNuevo';
import Restablecer from './components/auth/Restablecer';
import NoCobertura from './components/auth/NoCobertura';
import Pedidos from './components/perfil/Pedidos';
import Datos from './components/perfil/Datos';
import Inicio from './components/paginas/Inicio';
import LandingPage from './components/paginas/LandingPage';
import PoliticaDatos from './components/paginas/PoliticaDatos';
import PreguntasFrecuentes from './components/paginas/PreguntasFrecuentes';
import EstadoCuenta from './components/perfil/EstadoCuenta';

//REDUX
import ActualizarUsuario from './store/usuario/action';
import ActualizarCarrito from './store/carrito/action';

// SASS
import './styles/app.scss';
import CreacionUsuarios from './components/perfil/CreacionUsuarios';
import firebase from 'firebase';
import Cartera from './components/perfil/Cartera';
import AlertModal from './components/modal/AlertModal';
import Prueba from './components/pedido/Pruebas';

// AGREGA TOKEN AL ENCABEZADO
Auth.headerToken();

// COMPONENTES
const App = (props) => {
  const [alerta, setAlerta] = useState();

  const getUser = async () => {
    let url = Constants.URL_BASE + Constants.WSACTUALIZARDATOS;
    axios.get(url)
      .then(res => {
        props.ActualizarUsuario(res.data);
        Auth.setDoc(res.data.usuario);
        Auth.setTipoUsuario(res.data.tipoUsuario);
      })
      .catch(() => {
        firebase.analytics().logEvent("logout");
        Auth.signout();
        window.location.reload(true);
      });
  }

  useEffect(() => {
    if (Auth.getToken()) {
      getUser();
    }

    if (Auth.getCarrito()) {
      props.ActualizarCarrito(Auth.getCarrito());
    }
    
    if (Auth.getNumDoc() || Auth.getCartera()) {
      verificarEstado(Auth.getNumDoc() ? 1 : 2);
    }
  }, [])

  const verificarEstado = (tipoC) => {
    let url = Constants.URL_BASE + Constants.WSVALIDARESTADO + "?numDoc=" + (tipoC == 1 ? Auth.getNumDoc() : Auth.getCartera());
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`
      }
    })
      .then((response) => {
        actualizarCupo();
        if (response.data.code != "180") {
          let tipo = "success";
          if (response.data.code == "200") {
            tipo = "success";
          } else if (response.data.code == "210") {
            tipo = "warning";
          } else {
            tipo = "error";
          }

          setAlerta({
            tipo: tipo,
            titulo: (tipoC == 1 ? "Pedido." : "Cartera."),
            mensaje: response.data.message,
            btnOk: "Aceptar",
            fnOk: function () {
              if (tipoC == 1) {
                Auth.removeCarrito()
                props.ActualizarCarrito({})
                window.location.reload(true);
              }
            },
            fnCancel: null
          })
        }

        if (tipoC == 1) {
          Auth.removeNumDoc();
        } else {
          Auth.removeCartera();
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const actualizarCupo = () => {
    let sucursal = Auth.getSucursal();
    let url = Constants.URL_BASE + Constants.WSCUPOCLIENTE + sucursal.codigoSac;
    axios.get(url)
        .then(res => {
            var sucursalC = Auth.getSucursal();
            sucursalC.cupo = res.data.cupo;
            Auth.setSucursal(sucursalC);
        })
        .catch(err => {
            console.log(err);
        });
}


  const NotFoundRedirect = () => <Redirect to='/' />

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/registro" component={Registro} />
          <Route exact path="/RegistroNuevo" component={RegistroNuevo} />
          <Route exact path="/pago/:numdoc" component={Prueba} />
          <Route exact path="/recuperar-clave" component={Restablecer} />
          <Route exact path="/manual" component={LandingPage} />
          <Route exact path="/preguntas-frecuentes" component={PreguntasFrecuentes} />
          <Route exact path="/politicas-datos" component={() => <PoliticaDatos type={2} />} />
          <Route exact path="/terminos-y-condiciones" component={() => <PoliticaDatos type={1} />} />
          <Route exact path="/garantias-y-devoluciones" component={() => <PoliticaDatos type={4} />} />
          <Route exact path="/disposiciones-privacidad-seguridad" component={() => <PoliticaDatos type={5} />} />
          <PrivateRoute exact path="/cobertura" component={Cobertura} />
          <PrivateRoute exact path="/no-cobertura" component={NoCobertura} />
          <PrivateRoute exact path="/" component={Inicio} />
          <PrivateRoute exact path="/catalogo/:categoria" component={Catalogo} />
          <PrivateRoute exact path="/producto/:producto" component={Detalle} />
          <PrivateRoute exact path="/seccion/:seccion" component={Seccion} />
          <PrivateRoute exact path="/pack/:pack" component={Pack} />
          <PrivateRoute exact path="/carrito" component={Carrito} />
          <PrivateRoute exact path="/pedidos" component={Pedidos} />
          <PrivateRoute exact path="/datos" component={Datos} />
          <PrivateRoute exact path="/creacion-usuarios" component={CreacionUsuarios} />
          <PrivateRoute exact path="/estado-cuenta" component={EstadoCuenta} />
          <Route exact path="/cartera" component={Cartera} />
          <Route component={NotFoundRedirect} />
        </Switch>
      </BrowserRouter>
      {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
    </React.Fragment>
  )
}

// RUTAS PRIVADAS
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.getToken()
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
  
)


export default connect(null, { ActualizarUsuario, ActualizarCarrito })(App);

