import React, { Component } from "react";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';
import Progress from "./Progress";

class TabletC extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      data: [],
      checked: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(
      this
    );
  }



  handleChange = () => {
    var selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    this.state.data.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    this.setState({
      checked: checkedCopy
    });

    if (selectAll) {
      this.props.updateTotal(this.state.data);
    } else {
      this.props.updateTotal([]);
    }
  };

  handleSingleCheckboxChange = index => {

    var checkedCopy = this.state.checked;
    checkedCopy[index] = !this.state.checked[index];
    if (checkedCopy[index] === false) {
      this.setState({ selectAll: false });
    }

    this.setState({
      checked: checkedCopy
    });

    var data2 = [];

    this.state.data.map((item, i) => {
      if (checkedCopy[i] === true) {
        data2.push(item);
      }
    })


    this.props.updateTotal(data2);
  };

  handleChangeCantidad = (itemP, valor, index) => {
    var validar = false;

    if ((valor <= 0 && Object.keys(valor).length > 0) || (valor > itemP.saldo)) {
      var checkedCopy = this.state.checked;
      checkedCopy[index] = false;

      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if (checkedCopy[i] === true) {
          data2.push(item);
        }

        if (itemP.documento === item.documento) {
          item.saldoPago = item.saldo;
          dataReset.push(item);
        } else {
          dataReset.push(item);
        }
      })

      this.setState({
        data: dataReset,
        checked: checkedCopy
      });
      this.props.updateTotal(data2);
    } else if (valor <= itemP.saldo) {
      validar = true;
    }

    if (validar) {
      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if(this.state.checked[i] === true){
          if (itemP.documento === item.documento) {
            item.saldoPago =  parseInt(valor);
          }
          data2.push(item);
        }
        
        dataReset.push(item);
      })

      this.setState({
        data: dataReset
      });

      this.props.updateTotal(data2);
    } else {
      this.props.updateAlerta({
        tipo: "warning",
        titulo: "Advertencia",
        mensaje: "Por favor ingrese una cantidad valida",
        btnOk: "Aceptar",
        fnOk: function () { },
        fnCancel: null
      });
    }

  }

  handleBlur = (itemP, valor, index) => {
    var validar = false;

    if ((valor <= 0 && Object.keys(valor).length > 0) || (valor > itemP.saldo)) {
      var checkedCopy = this.state.checked;
      checkedCopy[index] = false;

      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if (checkedCopy[i] === true) {
          data2.push(item);
        }

        if (itemP.documento === item.documento) {
          item.saldoPago = item.saldo;
          dataReset.push(item);
        } else {
          dataReset.push(item);
        }
      })

      this.setState({
        data: dataReset,
        checked: checkedCopy
      });
      this.props.updateTotal(data2);
    } else if (valor <= itemP.saldo) {
      validar = true;
    }

    if (validar) {
      var data2 = [];
      var dataReset = [];

      this.state.data.map((item, i) => {
        if(this.state.checked[i] === true){
          if (itemP.documento === item.documento) {
            item.saldoPago =  parseInt(valor);
          }
          data2.push(item);
        }
        
        dataReset.push(item);
      })

      this.setState({
        data: dataReset
      });

      this.props.updateTotal(data2);
    } else {
      this.props.updateAlerta({
        tipo: "warning",
        titulo: "Advertencia",
        mensaje: "Por favor ingrese una cantidad valida",
        btnOk: "Aceptar",
        fnOk: function () { },
        fnCancel: null
      });
    }
  };

  componentDidMount() {
    var data2 = this.props.data;

    var checkedCopy = [];
    var selectAll = this.state.selectAll;
    data2.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });

    this.setState({
      data: data2,
      checked: checkedCopy,
      selectAll: selectAll
    });

    this.setState({
      data: data2,
      checked: checkedCopy,
      selectAll: selectAll
    });

  }

  render() {
    return (
      <div className="table-responsive">

        <table className="mytabla mytabla-responsive" id="cartera">
          <thead>
            <tr>
              <th><input
                type="checkbox"
                onChange={() => this.handleChange()}
                checked={this.state.selectAll}
              /></th>
              <th className="text-left">Codigo SAP</th>
              <th className="text-left">Referencia</th>
              <th className="text-left">Documento Contable</th>
              <th className="text-left">Concepto</th>
              <th className="text-left">CPag</th>
              <th className="text-left">Fecha doc.</th>
              <th className="text-left">Fecha base</th>
              <th className="text-left">Venc.neto</th>
              <th className="text-left">Mora</th>
              <th className="text-left">Valor</th>
              <th className="text-left">Pago Parcial</th>
              <th className="text-left">Texto</th>
            </tr>
          </thead>
          <tbody className="table-hover">
            {this.state.data.map((item, i) => (
              <tr key={i}>
                <td className="text-left">
                  <input
                    type="checkbox"
                    defaultChecked={this.state.checked[i]}
                    checked={this.state.checked[i]}
                    onChange={() => this.handleSingleCheckboxChange(i)}
                  />
                </td>
                <td className="text-left">{item.codigoSac}</td>
                <td className="text-left">{item.referencia}</td>
                <td className="text-left">{item.documento}</td>
                <td className="text-left">{item.descCausaDev}</td>
                <td className="text-left">{item.condPago}</td>
                <td className="text-left">{item.fechaDoc.substring(6, 8) + "." + item.fechaDoc.substring(4, 6) + "." + item.fechaDoc.substring(0, 4)}</td>
                <td className="text-left">{item.fechaVencimiento}</td>
                <td className="text-left">{item.fechaVecto.substring(6, 8) + "." + item.fechaVecto.substring(4, 6) + "." + item.fechaVecto.substring(0, 4)}</td>
                <td className="text-left">{item.mora}</td>
                <td className="text-left">{" $" + Intl.NumberFormat().format(item.saldo)}</td>
                <td className="text-left">{this.state.checked[i] ?
                  (<input type="number"
                    onChange={e => this.handleChangeCantidad(item, e.target.value, i)}
                    onBlur={e => this.handleBlur(item, e.target.value, i)}
                    value={item.saldoPago} />) : null}</td>
                <td className="text-left">{item.concepto}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TabletC;
