import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import firebase from '../../constants/firebase';
import { withRouter } from "react-router-dom";
import Progress from '../util/Progress';

import Navbar from '../navbar/Navbar';
import Sub_nav from '../navbar/Sub_nav';
import AlertModal from '../modal/AlertModal';
import CheckOut from '../checkout/CheckOut';
import Footer from '../footer/Footer';
import Search from '../util/Search';

import * as Constants from '../../constants/global';
import Auth from '../../helpers/Auth';

// REDUX
import ActualizarUsuario from '../../store/usuario/action';
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import { selectActiveUser } from '../../store/usuario/reducer';

import minus from '../../assets/icon/minus.svg';
import plus from '../../assets/icon/plus.svg';
import error from '../../assets/icon/error.svg';
import noimage from '../../assets/img/logo_login.png';
import eliminar from '../../assets/icon/eliminar.svg';
import CargarContenido from '../util/CargarContenido';
import CarritoObsequioModal from '../modal/CarritoObsequioModal';
import regalo from '../../assets/img/regalo.png'
import InnerHTML from 'dangerously-set-html-content'
import Moment from 'moment';
import Login from '../auth/Login';

const Carrito = (props) => {

    const style = {
        'text-align': 'center',
        'padding-top': '130px',
        'font-size': '1.5rem'
    }

    const [alerta, setAlerta] = useState();
    const [total, setTotal] = useState(0);
    const [envio, setEnvio] = useState(0);
    const [tipoEnvio, setTipoEnvio] = useState(2);
    const [descuento, setDescuento] = useState(0)
    const [sucursal, setSucursal] = useState('');
    const [observacion, setObservacion] = useState('');
    const [iva, setIva] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [checkOut, setCheckOut] = useState(false);
    const [loader, setLoader] = useState(false);
    const [comprando, setComprando] = useState(false);
    const [enviado, setEnviado] = useState(false)
    const [message, setMessage] = useState();
    const [topPedido, setTopePedido] = useState();
    const [calculandoPrecio, setCalculandoPrecio] = useState(true);
    const [listaObsequios, setListaObsequios] = useState([]);
    const [listaObsequiosCarrito, setListaObsequiosCarrito] = useState({});
    const [modalObsequios, setModalObsequios] = useState();
    const [numDoc, setNumDoc] = useState("");
    const [tipoPago, setTipoPago] = useState("");
    const [cupon, setcupon] = useState("")
    const [cuponDescuento, setcuponDescuento] = useState(0)

    var listaObsequiosTemp;

    let contador = 0;


    const calcularPedido = (validarObsequio) => {
        let listCarrito = Auth.getCarrito();
        let subtotal = 0;
        let total = 0;
        let descuentos = 0;
        let totalIva = 0;
        Object.keys(listCarrito).map((item, i) => {
            var producto = listCarrito[item];
            let cantidad = listCarrito[item]["cantidad"];
            let montoDescuento = (listCarrito[item]["precio"] * cantidad) * (listCarrito[item]["porcDesc"] / 100);
            let sub_total = cantidad * listCarrito[item]["precio"];
            let montoIva = (sub_total - montoDescuento) * (listCarrito[item]["porcIva"] / 100.0);
            let totalItem = (sub_total - montoDescuento) * (1 + listCarrito[item]["porcIva"] / 100.0);

            subtotal += sub_total;
            total += totalItem;
            descuentos += montoDescuento;
            totalIva += montoIva;


            if (validarObsequio) {
                let lista = listaObsequiosTemp == null ? listaObsequios : listaObsequiosTemp;
                lista.forEach(obsequio => {
                    var validacion = false;
                    if (obsequio.tipoAlcance == 2 && producto.idSeccion == obsequio.codigoValidacion
                        || obsequio.tipoAlcance == 1 && producto.idSeccion == obsequio.codigoValidacion
                        || obsequio.tipoAlcance == 3) {
                        if (obsequio.total > 0) {
                            obsequio.total = obsequio.total - totalItem;
                        }

                        if (obsequio.total <= 0) {
                            validacion = true;

                        }
                    }

                    if (validacion) {
                        listaObsequiosCarrito[obsequio.codigoSku] = {
                            id: obsequio.idProducto,
                            nombre: obsequio.descripcion,
                            precio: 0,
                            iva: 0,
                            ico: 0,
                            cantidad: 1,
                            descuento: 0,
                            inventario: 1,
                            idCategoria: obsequio.categoriaId,
                            idSeccion: obsequio.seccionId,
                            regalo: obsequio.id
                        }
                    }
                });
            }

        });

        setTotal(Math.round(total));
        setDescuento(Math.round(descuentos));
        setIva(Math.round(totalIva));
        setSubTotal(Math.round(subtotal));

        setCalculandoPrecio(false);
        contador = 0;

        if (validarObsequio) {
            //console.log(listaObsequiosCarrito);
            if (Object.keys(listaObsequiosCarrito).length > 1) {
                setModalObsequios(listaObsequiosCarrito);
            } else if (Object.keys(listaObsequiosCarrito).length == 1) {
                var carrito = props.carrito || {};

                Object.keys(listaObsequiosCarrito).forEach(codigoSku => {
                    carrito[codigoSku] = {
                        id: listaObsequiosCarrito[codigoSku].id,
                        nombre: listaObsequiosCarrito[codigoSku].nombre,
                        precio: 0,
                        cantidad: 1,
                        iva: 0,
                        ico: 0,
                        descuento: 0,
                        inventario: 1,
                        idCategoria: listaObsequiosCarrito[codigoSku].categoriaId,
                        idSeccion: listaObsequiosCarrito[codigoSku].seccionId,
                        regalo: listaObsequiosCarrito[codigoSku].regalo,
                        porcDesc: 0,
                        porcIva: 0
                    }
                });
                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());
            } else {
                /*var validar = false;
                var carrito = props.carrito || {};
                Object.keys(carrito).forEach(codigoSku => {
                    if (carrito[codigoSku].regalo != 0) {
                        delete carrito[codigoSku];
                        validar = true;
                    }
                });*/

            }

        }

    }
    const limpiarRegalos = () => {
        var validar = false;
        var carrito = props.carrito || {};
        Object.keys(carrito).forEach(codigoSku => {
            if (carrito[codigoSku].regalo != 0) {
                delete carrito[codigoSku];
                validar = true;
            }
        });
        if (validar) {
            setAlerta({
                tipo: "warning",
                titulo: "Carrito.",
                mensaje: "Al realizar un cambio de cantidades, se validará nuevamente si tiene obsequios aplicables",
                btnOk: "Aceptar",
                fnOk: function () {

                    Auth.setCarrito(carrito);
                    props.ActualizarCarrito(Auth.getCarrito());
                },
                fnCancel: null
            })
        }

    }


    // AUMENTA LA CANTTIDAD
    const handleMas = (codigoSku, item) => {
        setCheckOut(false);
        var carrito = props.carrito || {};
        if (carrito[codigoSku]) {
            if (parseInt(carrito[codigoSku]['cantidad']) < carrito[codigoSku].inventario) {

                const valor = carrito[codigoSku]["cantidad"] + 1;
                let estado = true;

                if (carrito[codigoSku].maximo != -1) {
                    if (carrito[codigoSku].maximo < valor) {
                        estado = false;
                        setAlerta({
                            tipo: "warning",
                            titulo: "Advertencia.",
                            mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[codigoSku].maximopPedido + " cada " + sucursal.diasPedido + " dias",
                            btnOk: "Aceptar",
                            fnOk: function () {
                                if (carrito[item.codigoSku].maximo == 0) {
                                    delete carrito[item.codigoSku];
                                } else {
                                    carrito[codigoSku]["cantidad"] = parseInt(carrito[codigoSku].maximo);
                                }
                                actualizarValores(carrito);
                            },
                            fnCancel: null
                        })
                    }
                }

                if (estado) {
                    carrito[codigoSku]["cantidad"] += 1;
                    actualizarValores(carrito);
                }

            }
            else {
                setAlerta({
                    tipo: "warning",
                    titulo: "Advertencia.",
                    mensaje: "Lo sentimos, inventario disponible " + carrito[codigoSku].inventario,
                    btnOk: "Aceptar",
                    fnOk: function () { },
                    fnCancel: null
                })
            }
        } else {
            carrito[codigoSku] = {
                nombre: item.nombre,
                precio: item.precio,
                cantidad: 1,
                iva: 0,
                descuento: 0,
                ico: parseInt(item.ico)
            }
        }

    }



    const handleMenos = (codigoSku, item) => {
        limpiarRegalos();
        let user = Auth.getSucursal()
        setCheckOut(false);
        var carrito = props.carrito || {};

        if (carrito[codigoSku]) {
            if (carrito[codigoSku]["cantidad"] == 1) {
                delete carrito[codigoSku];

                setCalculandoPrecio(true);
                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());
                calcularPedido(false);
            } else {
                const valor = carrito[codigoSku]["cantidad"] - 1;
                let estado = true;
                if (carrito[codigoSku].minimo != -1) {
                    if (carrito[codigoSku].minimo > valor) {
                        estado = false;
                        setAlerta({
                            tipo: "warning",
                            titulo: "Advertencia.",
                            mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[codigoSku].minimo,
                            btnOk: "Aceptar",
                            fnOk: function () {
                                delete carrito[codigoSku];
                                actualizarValores(carrito);
                            },
                            fnCancel: null
                        })
                    }
                }
                if (estado) {
                    carrito[codigoSku]["cantidad"] -= 1;
                    actualizarValores(carrito);
                }
            }
        }

    }

    const handleBlur = (codigoSku, item, valor) => {
        setCheckOut(false);
        limpiarRegalos();
        var carrito = props.carrito || {};
        if (Object.keys(valor).length == 0) {
            delete carrito[codigoSku];
            setCalculandoPrecio(true);
            Auth.setCarrito(carrito);
            props.ActualizarCarrito(Auth.getCarrito());
            calcularPedido(false);
        } else {
            if (carrito[codigoSku]) {
                if (parseInt(valor) <= carrito[codigoSku].inventario) {

                    let estado = true;

                    if (carrito[codigoSku].minimo != -1) {
                        if (carrito[codigoSku].minimo > valor) {
                            estado = false;
                            setAlerta({
                                tipo: "warning",
                                titulo: "Advertencia.",
                                mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[codigoSku].minimo,
                                btnOk: "Aceptar",
                                fnOk: function () {
                                    carrito[codigoSku]["cantidad"] = parseInt(carrito[codigoSku].minimo);
                                    actualizarValores(carrito);
                                },
                                fnCancel: null
                            })
                        }
                    }
                    if (carrito[codigoSku].maximo != -1) {
                        if (carrito[codigoSku].maximo < valor) {
                            estado = false;
                            setAlerta({
                                tipo: "warning",
                                titulo: "Advertencia.",
                                mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[codigoSku].maximopPedido + " cada " + sucursal.diasPedido + " dias",
                                btnOk: "Aceptar",
                                fnOk: function () {
                                    if (carrito[item.codigoSku].maximo == 0) {
                                        delete carrito[item.codigoSku];
                                    } else {
                                        carrito[codigoSku]["cantidad"] = parseInt(carrito[codigoSku].maximo);
                                    }
                                    actualizarValores(carrito);
                                },
                                fnCancel: null
                            })
                        }
                    }

                    if (estado) {
                        carrito[codigoSku]["cantidad"] = parseInt(valor);
                        actualizarValores(carrito);
                    }
                } else {
                    carrito[codigoSku]["cantidad"] = carrito[codigoSku]["cantidad"];
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, inventario disponible " + carrito[codigoSku].inventario,
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                }
            } else {
                carrito[codigoSku] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor),
                    iva: 0,
                    descuento: 0,
                    ico: parseInt(item.ico)
                }
            }
        }
    };

    const handleChangeCantidad = (codigoSku, item, valor) => {
        var carrito = props.carrito || {};
        if (valor <= 0 && Object.keys(valor).length > 0) {
            delete carrito[codigoSku];
        } else {
            if (carrito[codigoSku]) {
                if (valor <= carrito[codigoSku].inventario) {
                    carrito[codigoSku]["cantidad"] = parseInt(valor);
                } else {
                    carrito[codigoSku]["cantidad"] = carrito[codigoSku]["cantidad"];
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, inventario disponible " + carrito[codigoSku].inventario,
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                }
            } else {
                carrito[codigoSku] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor),
                    iva: 0,
                    descuento: 0,
                    ico: parseInt(item.ico)
                }
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        calcularPedido(false);
    }

    const handleEliminar = (item) => {
        setCheckOut(false);
        limpiarRegalos();
        var carrito = props.carrito || {};
        if (carrito[item].cantidad > 0) {
            setAlerta({
                tipo: "error",
                icon: Constants.URL_BASE + 'image/' + (item || '') + '.jpg',
                titulo: "Eliminar producto.",
                mensaje: "¿Está seguro de eliminar " + carrito[item].nombre + " ?",
                btnOk: "Si, eliminar",
                btnCancel: "No, cancelar",
                fnOk: function () {
                    carrito[item].cantidad = 1;
                    handleMenos(item);
                    setcuponDescuento(0);
                },
                fnCancel: function () { }
            })
        }
    }

    const handleCancelarPedido = () => {
        setCheckOut(false);
        setAlerta({
            tipo: "error",
            titulo: "Cancelar pedido.",
            mensaje: "¿Esta seguro de cancelar el pedido?. Se perderan todos los productos.",
            btnOk: "Si, eliminar",
            btnCancel: "No, cancelar",
            fnOk: function () {
                Auth.removeCarrito();
                props.ActualizarCarrito({});
                calcularPedido(false);
                setcuponDescuento(0);
            },
            fnCancel: function () { }
        })
    }

    const handelCheckOut = (comprobar) => {
        if(Auth.getDoc() ==='1111111111'){
            props.history.push('/login')
        }
        setComprando(!comprobar);
        let cliente = props.usuario
        let carrito = Auth.getCarrito();
        let mensajeInventario = "";
        if (cliente) {
            let detalle = [];
            Object.keys(carrito).map((item, i) => {
                detalle.push({
                    "codigoSku": item,
                    "precio": carrito[item].precio,
                    "iva": carrito[item].iva,
                    "ico": carrito[item].ico
                })
            });

            let url = Constants.URL_BASE + Constants.WSVALIDARINVENTARIOCARRITO + "?CodigoCliente=" + sucursal.codigoSac;
            axios.post(url, { "detalle": detalle }).then((res) => {
                if (res.data) {
                    var dataArray = res.data;
                    dataArray.map((item, i) => {


                        if (carrito[item.codigoSku]) {
                            if (item.inventario !== carrito[item.codigoSku].inventario) {
                                carrito[item.codigoSku].inventario = item.inventario;
                            }

                            if (carrito[item.codigoSku].cantidad > item.inventario) {
                                mensajeInventario = "Por favor verifique la cantidad solicitada para los productos.";
                                carrito[item.codigoSku].cantInventario = true;
                            } else {
                                carrito[item.codigoSku].cantInventario = false;

                            }
                        }
                    });
                } else {
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, no hay inventario disponible ",
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                if (mensajeInventario.length > 0) {
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: mensajeInventario,
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                    setComprando(false);
                    setCheckOut(false)
                } else {
                    setCheckOut(true)
                }

                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());

                validatelistaObsequios(true, mensajeInventario, comprobar);

            });


        } else {
            setAlerta({
                tipo: "info",
                titulo: "Inicio de sesión.",
                mensaje: "Por favor inicie sesión o registrese para terminar el pedido",
                btnOk: "Ingresar o Registrarse",
                btnCancel: "Cancelar",
                fnOk: function () {
                    props.history.push('/login');
                },
                fnCancel: function () { }
            })
        }
    }


    const handlePedido = (temp) => {
        generarNumDoc();
        if (tipoEnvio == 2) {
            setAlerta({
                tipo: "error",
                titulo: "Pedido.",
                mensaje: "Por favor seleccione la fecha de entrega",
                btnOk: "Aceptar",
                fnOk: function () {
                    setComprando(false)
                },
                fnCancel: null
            })
            return;
        }
        if (temp == 1) {
            setLoader(true)
        }
        let cliente = props.usuario

        let detalle = [];
        let ubicacion = Auth.getUbicacion();

        Object.keys(props.carrito).map((item, i) => {
            detalle.push({
                "codigoSku": item,
                "cantidad": props.carrito[item].cantidad,
                "PorcIva": props.carrito[item].porcIva,
                "Precio": Math.round(props.carrito[item].precio),
                "PorcDesc": parseFloat(props.carrito[item].porcDesc),
                "IdProgObsequio": props.carrito[item].regalo == 0 ? -1 : props.carrito[item].regalo
            })
        })

        let totalPedido = cuponDescuento === 0 ? total + envio : ((total + envio) - ((total + envio) * cuponDescuento) / 100);
        let pedido = {
            numDoc: numDoc,
            numdocE: tipoEnvio == 1 && envio != 0 ? "BC" + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds() : "",
            observacion: observacion,
            tipoPago: tipoPago,
            clienteId: cliente.id,
            plataforma: "WEB",
            fecha: cliente.fecha,
            detalle: detalle,
            codigoSac: sucursal.codigoSac,
            // Total: total + envio,
            Total: Math.round(totalPedido),
            Descuento: descuento,
            Iva: iva,
            envioExpress: tipoEnvio,
            costoEnvio: envio,
            temp: temp,
            idTransaccion: "",
            ubicacion: ubicacion,
            codigoCupon: cupon
        }

        if (cliente) {
            enviarPedido(pedido);
        }
    }

    const handlePago = () => {

        handlePedido(0);

    }

    const handleRedirect = item => {
        props.history.push(item);
    }

    const cargarDatos = () => {
        var sucursal = Auth.getSucursal();
        setTopePedido(sucursal.pedidoMinimo);
    }

    /* const obtenerDescuento = (codigoSku, carrito, cantidadProducto) => {
        setCalculandoPrecio(true);
        let user = Auth.getSucursal()
        let data = carrito[codigoSku];
        let url = Constants.URL_BASE + Constants.WSDESCUENTO + "/?codProducto=" + codigoSku + "&CodigoSac=" + user.codigoSac + "&cantidad=" + data.cantidad;
        axios.post(url)
            .then(res => {
                const response = res.data
                if (response.length > 0) {
                    carrito[codigoSku]["descuento"] = 0;
                    carrito[codigoSku]["iva"] = 0;
                    let desc = 0;
                    let descAcumulado = 0;
                    let descPorc = 0;
                    let precio = data.precio;
                    //console.log(data.precio);

                    response.forEach(item => {

                        const d = item;
                        desc = precio * (d.descuento / 100);
                        descPorc += d.descuento;
                        precio = precio - desc;
                        descAcumulado += desc;

                    });
                    const porcIva = carrito[codigoSku]["porcIva"];

                    let perDescuento = ((precio * 100) / data.precio).toFixed(1);
                    if (response.length) {
                        perDescuento = 100 - perDescuento;
                    } else {
                        perDescuento = descPorc;
                    }

                    carrito[codigoSku]["porcDesc"] = perDescuento.toFixed(1);
                    carrito[codigoSku]["descuento"] = (data.precio * (perDescuento / 100));
                    const nuevoIva = porcIva > 0 ? (data.precio - (data.precio * (perDescuento / 100))) * (porcIva / 100) : 0
                    carrito[codigoSku]["iva"] = nuevoIva
                    //console.log(carrito[codigoSku]);
                } else {
                    const porcIva = carrito[codigoSku]["porcIva"];
                    carrito[codigoSku]["descuento"] = 0;
                    carrito[codigoSku]["porcDesc"] = 0;
                    const iva = porcIva > 0 ? (data.precio) * (porcIva / 100) : 0;
                    carrito[codigoSku]["iva"] = iva;

                }
            })
            .catch(err => {
                console.log(err)
            }).finally(() => {
                contador++;
                if (cantidadProducto == contador || cantidadProducto == -1) {
                    Auth.setCarrito(carrito);
                    props.ActualizarCarrito(Auth.getCarrito());
                    calcularPedido(false);
                }
            });
    } */

    const actualizarValores = (carrito) => {
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        calcularPedido(false);
    }

    const validatePedido = () => {
        var sucursal = Auth.getSucursal();
        setSucursal(sucursal);
        let carrito = Auth.getCarrito();
        firebase.analytics().logEvent("carrito", { usuario: sucursal.codigoSac });
        setLoader(true);
        if (Object.keys(sucursal).length > 0) {
            let url = Constants.URL_BASE + Constants.WSCATALOGO +
                "?codListaP=" + sucursal.codListaP + "&CodigoSac=" + sucursal.codigoSac;
            let detalle = [];
            Object.keys(carrito).map((item) => {
                if (carrito[item].regalo == 0) {
                    detalle.push({
                        "codigoSku": item,
                        "precio": carrito[item].precio,
                        "iva": carrito[item].iva,
                        "ico": carrito[item].ico
                    })
                }
            })
            axios.post(url, { "detalle": detalle })
                .then((response) => {
                    var dataArray = response.data;
                    var carritoAux = {};
                    dataArray.map((item) => {
                        if (parseInt(carrito[item.codigoSku]['cantidad']) != 0) {
                            if (carrito[item.codigoSku]) {
                                carritoAux[item.codigoSku] = {
                                    nombre: carrito[item.codigoSku]['nombre'],
                                    precio: item.precio,
                                    cantidad: parseInt(carrito[item.codigoSku]['cantidad']),
                                    iva: parseInt(item.iva),
                                    ico: parseInt(item.ico),
                                    inventario: carrito[item.codigoSku]['inventario'],
                                    porcIva: item.porcIva,
                                    idCategoria: carrito[item.codigoSku]['idCategoria'],
                                    idSeccion: carrito[item.codigoSku]['idSeccion'],
                                    regalo: 0,
                                    minimo: carrito[item.codigoSku]['minimo'],
                                    maximo: carrito[item.codigoSku]['maximo'],
                                    descuento: 0,
                                    porcDesc: 0,
                                    iva: 0,
                                    marca: carrito[item.codigoSku]['marca']
                                }
                            }
                        } else {
                            delete carrito[item.codigoSku];
                        }

                    });
                    Auth.setCarrito(carritoAux);
                    props.ActualizarCarrito(Auth.getCarrito());
                    calcularPedido(false);

                    //forech() forProduc ifTipocAlcance {ValidaCategoria {validoTotal=!0 {resto valor producto a total}}} else{ValidaMarca{validoTotal=!0resto valor producto a total}}


                    //Object.keys(carritoAux).map(c => {
                    //    obtenerDescuento(c, carritoAux, Object.keys(carritoAux).length);
                    //});

                });
        }
    }

    useEffect(() => {
        setNumDoc((tipoEnvio == 0 ? "B" : envio == 0 ? "BES" : "BEF") + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds())
        validatelistaObsequios(false);
        validatePedido();
        setLoader(false);
        cargarDatos();      
        console.log("data de props: " , JSON.stringify(props))  
        // if(Auth.getDoc() ==='1111111111'){
        //     props.history.push('/login')
        // }

    }, [])


    const validatelistaObsequios = (cargarDatos, mensajeInventario, comprobar) => {
        var sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSOBSEQUIOSLISTA + "?sucursal=" + sucursal.codigoSac + "&nit=" + Auth.getDoc();
        axios.get(url).then(res => {
            let data = res.data || [];
            listaObsequiosTemp = data;
            setListaObsequios(data);
            if (cargarDatos) {
                setListaObsequiosCarrito({});

                calcularPedido(comprobar);

                if (!comprobar && mensajeInventario.length == 0) {
                    handlePedido(1);
                }
            }
        });
    }

    const handlePayment = () => {
        handlePedido(0);
    }

    const generarNumDoc = () => {
        setNumDoc((tipoEnvio == 0 ? "B" : envio == 0 ? "BES" : "BEF") + Moment(new Date()).format().replace(/[T:-]/g, '') + new Date().getMilliseconds())
    }

    const enviarPedido = (pedido) => {
        if (pedido.temp == 0) { Auth.setNumDoc(pedido.numDoc) }

        axios.post(Constants.URL_BASE + Constants.WSPEDIDO, pedido, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            if (response.data.code == "200") {
                firebase.analytics().logEvent("pedido", { usuario: sucursal.codigoSac });
                if (pedido.temp == 1) {
                    setEnviado(true)
                    setLoader(false)
                    setTotal(0)
                    setIva(0)
                    setMessage(response.data.message)
                    setCheckOut(false)
                    Auth.removeCarrito()
                    props.ActualizarCarrito({})
                    if (tipoPago == "CRE") {
                        actualizarCupo();
                    }
                    setAlerta({
                        tipo: "success",
                        titulo: "Pedido.",
                        mensaje: response.data.message,
                        btnOk: "Historial",
                        btnCancel: "Ir al catalogo",
                        fnOk: function () {
                            props.history.push('/pedidos')
                        },
                        fnCancel: function () {
                            props.history.push('/')
                        }
                    })
                }
                else {
                    let totalPedido = cuponDescuento === 0 ? total + envio : Math.round((total + envio) - ((total + envio) * cuponDescuento) / 100);
                    var checkout = new window.WidgetCheckout({
                        currency: 'COP',
                        // amountInCents: ((total + envio) * 100).toFixed(0),
                        amountInCents: (totalPedido * 100).toFixed(0),
                        reference: pedido.numDoc,
                        publicKey: process.env.REACT_APP_B2B,
                        redirectUrl: process.env.REACT_APP_URL_B2B
                    });

                    checkout.open(function (result) {
                        var transaction = result.transaction
                        Auth.removeCarrito()
                        props.ActualizarCarrito({})
                        window.location.reload(true);
                    })
                }
            } else {
                setAlerta({
                    tipo: "error",
                    titulo: "Pedido.",
                    mensaje: response.data.message,
                    btnOk: "Aceptar",
                    fnOk: function () {
                        Auth.removePedido()
                        props.history.push('/carrito')
                        window.location.reload(true);
                    },
                    fnCancel: null
                })
            }

        }).catch((err) => {
            console.log(err);
            setLoader(false)
        })
    }

    const actualizarCupo = () => {
        let sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSCUPOCLIENTE + sucursal.codigoSac;
        axios.get(url)
            .then(res => {
                var sucursalC = Auth.getSucursal();
                sucursalC.cupo = res.data.cupo;
                Auth.setSucursal(sucursalC);
            })
            .catch(err => {
                console.log(err);
            });
    }

    // useEffect(() => {
    //     handlePedido(1)
    //   }, [cuponDescuento]);

    const handleCupon = async (e) => {
        e.preventDefault()
        let url = Constants.URL_BASE + Constants.WSCUPONPORCENTAJE
        var promocion = {
            codigoCupon: cupon,
            usuario: Auth.getDoc()
        }
        await axios.post(url, promocion).then(({ data }) => {
            const { message, porcentaje, state } = data;
            console.log(data)
            if (state) {
                setcuponDescuento(porcentaje)
            }
            else {
                setAlerta({
                    tipo: "error",
                    titulo: "Cupon.",
                    mensaje: message,
                    btnOk: "Aceptar",
                    fnOk: function () {
                        window.location.reload(true);
                    },
                    fnCancel: null
                })
            }
        })

    }


    return (
        <React.Fragment>
            <div className="content-carrito">
                <div className="navbar principal navcarrito" >
                    <Navbar item={2} />
                    {/* <Search search={search} updateSearch={handleSearch} /> */}
                    <Sub_nav />

                </div>

                <div className="layout">
                    <div className="mobile">
                        <div className="pedido-list carrito">
                            {loader ? <Progress color={"#0194C5"} /> : (
                                <React.Fragment>
                                    {props.carrito && !enviado ? (
                                        <React.Fragment>
                                            {Object.keys(props.carrito).length > 0 ? (
                                                <React.Fragment>
                                                    <h1 className="titulo-carrio">Verifica tu pedido</h1>
                                                    <h3 className="titulo-carrio">Si es correcto da clic en confirmar pedido para continuar.</h3>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <h2 className="titulo-carrio">El carrito está vacío</h2>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <button className="btn-large btn-catalogo" onClick={e => handleRedirect('/')}>Ir al catálogo</button>
                                                    </div>

                                                </React.Fragment>
                                            )}

                                            <div className="product-list">
                                                {Object.keys(props.carrito).map((item, i) => (
                                                    <div className="item item-carrito" key={i}>
                                                        {props.carrito[item].regalo != 0 ?
                                                            (<React.Fragment><img
                                                                className="img-regalo"
                                                                src={regalo}>
                                                            </img></React.Fragment>)
                                                            : null
                                                        }
                                                        <img
                                                            className="img-product"
                                                            src={Constants.URL_BASE + '/image/' + item + '.jpg'}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                        </img>
                                                        <div className="start">

                                                            <p className="nombre">{props.carrito[item].nombre}</p>

                                                            {props.carrito[item].regalo == 0 ?
                                                                (<React.Fragment>{
                                                                    calculandoPrecio ?
                                                                        <CargarContenido carrito={2} /> :
                                                                        <div>
                                                                            {props.carrito[item].descuento > 0 && (
                                                                                <p className="precioD">
                                                                                    {" $" + new Intl.NumberFormat("en", {
                                                                                        numberingSystem: "latn",
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                        currency: "COP"
                                                                                    }).format((props.carrito[item].precio + props.carrito[item].iva))}
                                                                                </p>
                                                                            )}

                                                                            <p className="precio">
                                                                                {" $" + new Intl.NumberFormat("en", {
                                                                                    numberingSystem: "latn",
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                    currency: "COP"
                                                                                }).format((props.carrito[item].precio - props.carrito[item].descuento) + props.carrito[item].iva)}

                                                                            </p>
                                                                            <p className="regular">
                                                                                Total {" $" + new Intl.NumberFormat("en", {
                                                                                    numberingSystem: "latn",
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                    currency: "COP"
                                                                                }).format(((props.carrito[item].precio - props.carrito[item].descuento) + props.carrito[item].iva) * props.carrito[item].cantidad)}
                                                                            </p>
                                                                            {props.carrito[item].cantInventario ? (
                                                                                <p className="error">
                                                                                    Inventario Disponible {props.carrito[item].inventario}
                                                                                </p>
                                                                            ) : null}
                                                                            {props.carrito[item].iva > 0 && (
                                                                                <p className="regular">
                                                                                    Iva {props.carrito[item].porcIva}%
                                                                                </p>
                                                                            )}
                                                                            {props.carrito[item].descuento > 0 && (
                                                                                <p className="regular">
                                                                                    Descuento {props.carrito[item].porcDesc}%
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                }</React.Fragment>)
                                                                : null
                                                            }

                                                        </div>
                                                        <div className="end">
                                                            <div className="action">
                                                                {props.carrito[item].regalo == 0 ?
                                                                    (<React.Fragment>{props.carrito[item].inventario == 0 ? null :
                                                                        <div className="controls">
                                                                            <button role="button" onClick={() => handleMenos(item, props.carrito[item])}>
                                                                                <img src={minus} alt="icon minus" width="15px" height="15px"></img>
                                                                            </button>
                                                                            <input type="number" minLength="0" maxLength="99"
                                                                                onChange={e => handleChangeCantidad(item, props.carrito[item], e.target.value)}
                                                                                onBlur={e => handleBlur(item, props.carrito[item], e.target.value)}
                                                                                value={props.carrito[item].cantidad} />
                                                                            <button role="button" onClick={() => handleMas(item, props.carrito[item])}>
                                                                                <img src={plus} alt="icon plus" width="15px" height="15px"></img>
                                                                            </button>
                                                                        </div>
                                                                    }</React.Fragment>)
                                                                    : null
                                                                }

                                                                <a style={{ cursor: "pointer" }} onClick={() => handleEliminar(item)}>
                                                                    <img src={eliminar} alt="icon delete" width="30px" height="30px"></img>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    ) :
                                        <React.Fragment>
                                            {
                                                message ? <div style={style}>
                                                    <p dangerouslySetInnerHTML={{ __html: message }}></p>
                                                </div> : null
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            )}
                        </div>


                        <div className="total">
                            <div className="total-content">
                                {calculandoPrecio ?
                                    <CargarContenido carrito={1} />
                                    :
                                    <div>
                                        <div className="item">
                                            <p className="opc">Subtotal:</p>
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                    //}).format(total - iva + descuento)}
                                                }).format(subTotal)}
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Descuento:</p>
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                }).format(descuento)}
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Iva:</p>
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                }).format(iva)}
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Envío:</p>
                                            <p className="val">
                                                {envio == 0 ? "Por definir" :
                                                    (" $" + new Intl.NumberFormat("en", {
                                                        numberingSystem: "latn",
                                                        style: "decimal",
                                                        maximumFractionDigits: 0,
                                                        currency: "COP"
                                                    }).format((envio)))
                                                }</p>
                                        </div>
                                        <div className="item">
                                            <p className="opc">Total:</p>
                                            {
                                                cuponDescuento !== 0 && <p className="val precioTachado" style={{ textDecoration: "line-through" }}>
                                                    {" $" + new Intl.NumberFormat("en", {
                                                        numberingSystem: "latn",
                                                        style: "decimal",
                                                        maximumFractionDigits: 0,
                                                        currency: "COP"
                                                    }).format((total + envio))}
                                                </p>
                                            }
                                            <p className="val">
                                                {" $" + new Intl.NumberFormat("en", {
                                                    numberingSystem: "latn",
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                    currency: "COP"
                                                }).format(((total + envio) - (((total + envio) * cuponDescuento) / 100)))}
                                            </p>
                                        </div>
                                    </div>
                                }

                            </div>
                            <br />
                            <div className='container'>
                                <form>
                                    <div className="form-control">
                                        <label ><span style={{ color: "rgba(0, 0, 0, 0.85)" }}>Introduce tu codigo promocional</span>:</label>
                                        <input type='text' name="cupon"
                                            id="cupon"
                                            onChange={e => setcupon(e.target.value)}
                                            value={cupon}
                                            placeholder="Codigo promocional" />

                                        <button type="submit" className="btn-large" onClick={handleCupon} >Aplicar cupon</button>
                                    </div>
                                </form>
                            </div>
                            {/*  <p className="titulo-carrio">Si haces tu pedido antes de las 4pm será entregado mañana</p> */}


                            <React.Fragment>

                                {!loader && !message ? (
                                    <React.Fragment>
                                        {checkOut ? (
                                            <CheckOut checkout={setCheckOut} total={total} updateEnvio={setEnvio} updateTipoEnvio={setTipoEnvio} updateObservacion={setObservacion} updateTipoPago={setTipoPago} updateNumdoc={generarNumDoc} />
                                        ) : (
                                            null
                                        )}
                                        {!loader && sucursal.bloqueado !== "S" && sucursal.bloqueadoCartera !== "S"
                                            && ((sucursal.condicionPago == "DI" && (sucursal.pedidoMaximo !== -1 && sucursal.pedidoMaximo >= total || sucursal.pedidoMaximo == -1)) || sucursal.condicionPago !== "DI")
                                            && total >= topPedido && ((sucursal.cupo >= total && sucursal.condicionPago !== "DI") || sucursal.condicionPago == "DI") ? (
                                            <React.Fragment>
                                                {!comprando ?
                                                    <React.Fragment>
                                                        {checkOut ? (
                                                            <>
                                                                {!tipoPago || tipoEnvio == 2 ? (
                                                                    <div className="carrito-validacion">
                                                                        <div>
                                                                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                                                        </div>
                                                                        <div className="contenido">
                                                                            <p style={{ color: "red" }}><b>¡Recuerda!
                                                                            </b></p>
                                                                            <p>Debes seleccionar:</p>
                                                                            <p>{!tipoPago ? "Método de pago " : ""}</p>
                                                                            <p>{tipoEnvio == 2 ? "Opciones de envío" : ""}</p>
                                                                            <br />
                                                                            <p>Completa todos los datos para que ¡sigas disfrutando de nuestros productos!</p>

                                                                        </div>

                                                                    </div>
                                                                )
                                                                    : (
                                                                        tipoPago === "WPL" ?
                                                                            <button type="button" className="btn-large" onClick={e => handlePago()}>Realizar pago</button> :
                                                                            <button type="button" className="btn-large" onClick={e => handelCheckOut(false)}>Finalizar pedido</button>
                                                                    )

                                                                }
                                                                {/* */}
                                                            </>
                                                        ) : (
                                                            <button type="submit" className="btn-large" onClick={e => handelCheckOut(true)}>Confirmar pedido</button>
                                                        )}
                                                    </React.Fragment>
                                                    : null
                                                }

                                            </React.Fragment>
                                        ) : (
                                            null
                                        )}

                                        <button type="button" className="btn-large outline" onClick={() => handleRedirect('/')}>Agregar más productos</button>

                                        {Object.keys(props.carrito).length > 0 ? (
                                            <button type="button" className="btn-large outline" onClick={e => handleCancelarPedido()}>Cancelar pedido</button>
                                        ) : null}
                                    </React.Fragment>
                                ) : (
                                    null
                                )}

                            </React.Fragment>

                            {sucursal.bloqueado == "S" || sucursal.bloqueadoCartera == "S" ? (
                                <div className="carrito-validacion">
                                    <div>
                                        <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                    </div>
                                    <div className="contenido">
                                        <p style={{ color: "red" }}><b>Cliente Bloqueado
                                        </b></p>
                                        {sucursal.bloqueado == "S" ?
                                            (<p>Lastimosamente en este momento presentas un bloqueo y no podrás tomar tu pedido.
                                                Te invitamos a ponerte al día en el pago de tus facturas para seguir disfrutando de todo
                                                lo que tenemos para ti. ¡Estamos a la orden!
                                                Cualquier información adicional que requieras comunícate con tu vendedor.</p>)
                                            :
                                            (<p>Tienes facturas pendientes por pagar, puedes realizar el pago dando <a onClick={e => handleRedirect('/cartera')}><b>clic aquí</b></a></p>)
                                        }

                                    </div>
                                </div>
                            ) : (
                                <React.Fragment>
                                    {sucursal.condicionPago !== "DI" && sucursal.cupo < total ? (
                                        <div className="carrito-validacion">
                                            <div>
                                                <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                            </div>
                                            <div className="contenido">
                                                <p style={{ color: "red" }}><b>Cupo disponible
                                                    {" $" + new Intl.NumberFormat("es-CO", {
                                                        style: "decimal",
                                                        currency: "COP"
                                                    }).format(sucursal.cupo)}
                                                </b></p>
                                                <p>En este momento presentas facturas pendientes por pagar,
                                                    puedes realizar tu pago dando <a onClick={e => handleRedirect('/cartera')}>clic aquí </a>,
                                                    si ya realizaste el pago recuerda que este se verá reflejado máximo en 48h.</p>

                                            </div>

                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {sucursal.condicionPago == "DI" && sucursal.pedidoMaximo !== -1 && sucursal.pedidoMaximo < total ? (
                                                <div className="carrito-validacion">
                                                    <div>
                                                        <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                                    </div>
                                                    <div className="contenido">
                                                        <p style={{ color: "red" }}><b>Cupo maximo de pedido disponible
                                                            {" $" + new Intl.NumberFormat("es-CO", {
                                                                style: "decimal",
                                                                currency: "COP"
                                                            }).format(sucursal.pedidoMaximo)}
                                                        </b></p>
                                                        <p>Esta superando este valor, por favor elimine productos</p>

                                                    </div>

                                                </div>
                                            ) : (
                                                <React.Fragment>


                                                </React.Fragment>
                                            )
                                            }
                                        </React.Fragment>
                                    )
                                    }

                                    {total < topPedido ? (
                                        <div className="carrito-validacion">
                                            <div>
                                                <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                            </div>
                                            <div className="contenido">
                                                <p style={{ color: "red" }}><b>El valor mínimo de envío es de
                                                    {" $" + new Intl.NumberFormat("es-CO", {
                                                        style: "decimal",
                                                        currency: "COP"
                                                    }).format(topPedido)}
                                                </b></p>
                                                <p>Completa el valor mínimo para que disfrutes de nuestros productos.</p>

                                            </div>

                                        </div>
                                    ) : (
                                        <React.Fragment>


                                        </React.Fragment>
                                    )
                                    }

                                </React.Fragment>
                            )
                            }


                        </div>
                    </div>

                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            {modalObsequios ? (<CarritoObsequioModal lista={modalObsequios} updateObsequio={setModalObsequios} />) : null}
            <Footer />
        </React.Fragment >
    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, { ActualizarCarrito, ActualizarUsuario })(Carrito));