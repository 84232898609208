import React, { useState, useEffect } from 'react'
import * as Constants from '../../constants/global'
import axios from 'axios'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import noimage from '../../assets/img/logo_login.png'

const Marca = () => {

    const [seccion, setSeccion] = useState();

    const options = {
        margin: 5,
        responsiveClass: true,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 3,
            },
            400: {
                items: 4,
            },
            600: {
                items: 5,
            },
            700: {
                items: 6,
            },
            1000: {
                items: 7,

            }
        },
    };

    useEffect(() => {
        const seccionData = () => {
            let url = Constants.URL_BASE + Constants.WSSECCION;
            axios.get(url)
                .then(res => {
                    setSeccion(res.data);
                });
        };
        seccionData();
    }, [])

    return (
        <div className="marca">
            {seccion ? (
                <>
                    <div className="inicio-titulo">
                        <h2> Mundos </h2>
                    </div>
                    <div className="marca-content">

                        {seccion.map((item, i) => (
                            <div className="item">
                                <a href={"/seccion/" + item.nombre}>
                                    <div key={i} value={item.id} style={{ backgroundColor: item.color }}>

                                        <img
                                            className="img-marca"
                                            src={Constants.URL_BASE + 'logo/' + item.logo.trim()}
                                            onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                        </img>
                                    </div>
                                    <p>{item.nombre}</p>
                                </a>
                            </div>
                        ))}

                    </div>
                </>
            ) : null}
        </div>
    )
}

export default Marca
