import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import MobileStoreButton from 'react-mobile-store-button';

import logo  from '../../assets/img/logo.png';
import downloadapp  from '../../assets/img/downloadapp.png';
import logo_cw from '../../assets/img/Logo_Cw_Blanco.png';
import facebook from '../../assets/icon/facebook.svg';
import twitter from '../../assets/icon/twitter.svg';
import linkedin from '../../assets/icon/linkedin.svg';
import instagram from '../../assets/icon/instagram.svg';
import youtube from '../../assets/icon/youtube.svg';

//doc
import pdfTerminos from '../../assets/terminos/pdfTerminos.pdf';
import pdfPolitica from '../../assets/terminos/pdfPolitica.pdf';
import pdfPoliticaTratamiento from '../../assets/terminos/pdfPoliticaTratamiento.pdf';
import pdfAntiFraude from '../../assets/terminos/pdfAntiFraude.pdf';

const Footer = (props) => {
    
    return (
    
    <div className="footer" >
            <div className="content">
                <div className="logo">
                    <div className="img-logo">
                        <img src={logo}/>
                    </div>
                    
                    
                </div>
                <div className="links">
                    <ul>
                        
                        <li><a href = {pdfTerminos} target = "_blank">Términos y condiciones de uso sitio web Alianza Team</a></li>
                        <li><a href = {pdfPolitica} target = "_blank">Política de privacidad sitio web Alianza Team</a></li>
                        
                    </ul>
                </div>
                <div className="redes">
                    
                    <div>
                        <li><a style={{color:'white'}} href="https://www.alianzateam.com" target="_blank"><b>www.alianzateam.com</b></a></li>
                        <li><a style={{color:'white'}} href="https://www.alianzateam.com/contacto-alianza-team/" target="_blank">Contáctanos</a></li>

                        <div className="icon-redes">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/AlianzaTeamOficial/" target="_blank"><img src={facebook} alt="icon delete" width="15px" height="15px"></img></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/alianzateam/about/" target="_blank"><img src={linkedin} alt="icon delete" width="15px" height="15px"></img></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UC0gC2L0omiRERl8HFbQrvHQ" target="_blank"><img src={youtube} alt="icon delete" width="15px" height="15px"></img></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default withRouter(Footer);