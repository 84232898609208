import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Auth from '../../helpers/Auth';

// REDUX
import ActualizarUsuario from '../../store/usuario/action';
import { selectActiveUser } from '../../store/usuario/reducer';
import AlertModal from '../modal/AlertModal';

const CheckOut = (props) => {

    const [fechaEntrega, setFechaEntrega] = useState();
    const [tipoEntrega, setTipoEntrega] = useState([]);
    const [sucursal, setSucursal] = useState("NONE");
    const [listaTipoPagos, setTipoPagos] = useState([]);
    const [alerta, setAlerta] = useState();
    const [dataDescuentosFlete, setDataDescuentosFlete] = useState([])

    const obtenerTipoEntrega = (descuentosFlete) => {
        var sucursal = Auth.getSucursal();
        let listCarrito = Auth.getCarrito();
        let url = Constants.URL_BASE + Constants.WSTIPOENTREGA;
        let items = [];
        Object.keys(listCarrito).map((item) => {
            items.push({
                "sku": item,
                "qty": listCarrito[item].cantidad,
            })
        });
        let fecha = {
            codigoSac: sucursal.codigoSac,
            origin: "",
            destination: "",
            items: items
        }
        axios.post(url, fecha, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }).then((response) => {
            let result = response.data
            console.log(result)
            if (result[0].valorFlete == 0) {
                setTipoEntrega(result);
            } else {
                if (descuentosFlete.length > 0) {
                    if (descuentosFlete[0].id == 1) {
                        let active = false
                        descuentosFlete[0].dataAux.map((x) => {
                            Object.keys(listCarrito).map((item) => {
                                if (x.producto == item && props.total > descuentosFlete[0].monto || parseInt(x.marca) == listCarrito[item].idCategoria && props.total > descuentosFlete[0].monto) {
                                    active = true
                                }
                            })
                        })
                        if (active == true) {
                            result[0].valorFlete = result[0].valorFlete - (result[0].valorFlete * descuentosFlete[0].subsidio) / 100
                            setTipoEntrega(result);
                        } else {
                            setTipoEntrega(result);
                        }

                    } else if (descuentosFlete[0].id == 2) {
                        if (props.total > descuentosFlete[0].monto) {
                            result[0].valorFlete = result[0].valorFlete - (result[0].valorFlete * descuentosFlete[0].subsidio) / 100
                            setTipoEntrega(result);
                        } else {
                            setTipoEntrega(result);
                        }
                    } else {
                        result[0].valorFlete = result[0].valorFlete - (result[0].valorFlete * descuentosFlete[0].subsidio) / 100
                        setTipoEntrega(result);
                    }
                } else {
                    setTipoEntrega(result);
                }

            }
        }).catch((err) => {
            setAlerta({
                tipo: "error",
                titulo: "Carrito.",
                mensaje: err.response.data.error,
                btnOk: "Aceptar",
                fnOk: function () {
                    props.checkout(false)
                },
                fnCancel: null
            })
        });
    }

    const obtenerTipoPagos = (envioExpress) => {
        var sucursal = Auth.getSucursal();
        let lista = [];
        lista.push({
            "descripcion": "Pago en línea",
            "value": "WPL"
        })

        /*  if ((sucursal.condicionPago == "DI" && envioExpress) || sucursal.condicionPago !== "DI") {
             lista.push({
                 "descripcion": sucursal.condicionPago !== "DI" ? "Crédito" : "Contra entrega, paga cuando recibas",
                 "value": sucursal.condicionPago !== "DI" ? "CRE" : "CON"
             })
         } */
        setTipoPagos(lista);

    }

    const handleObservacion = (value) => {
        props.updateObservacion(value)
    }

    const handleFecha = (value, item) => {
        let cliente = props.usuario
        var item = tipoEntrega[value];

        props.updateEnvio(item.valorFlete)
        props.updateTipoEnvio(item.tipoEntrega)
        cliente.fecha = item.fecha;
        props.ActualizarUsuario(cliente);
    
        setTipoPagos([]);
        props.updateTipoPago(null);
        setTimeout(function () {
            obtenerTipoPagos(item.tipoEntrega == 0 ? true : false);
        }, 2000);
        props.updateNumdoc();
    }

    const handleMetodoPago = (value) => {
        props.updateTipoPago(value);
        props.updateNumdoc();
    }


    const tipoEnvioResp = (item) => {
        let resp = " " + item.resultado;
        resp += " - Envio Normal" + (" $" + new Intl.NumberFormat("es-CO", {
            style: "decimal",
            currency: "COP"
        }).format(item.valorFlete));
        /* if (item.tipoEntrega == 0) {
            resp += " - Envio Normal";
        } else {
            resp += " - Envio exprés";
        }

        if (item.valorFlete == 0) {
            resp += " Gratis";
        } else {
            resp += (" $" + new Intl.NumberFormat("es-CO", {
                style: "decimal",
                currency: "COP"
            }).format(item.valorFlete));
        } */

        return resp;
    }

    const infoTipoEnvio = (item) => {
        let resp = "";

        /*  if (item.valorFlete != 0) {
             resp += "Te faltan " + (
                 " $" + new Intl.NumberFormat("es-CO", {
                     style: "decimal",
                     currency: "COP",
                     maximumFractionDigits: 0
                 }).format(item.valorMaxFlet - props.total) + " para que tu envío sea ¡gratis!");
         } */

        return resp;
    }

    const getDescuentosFletes = () => {
        axios.get(Constants.URL_BASE + Constants.GETDESCUENTOSFLETE)
            .then((res) => {
                obtenerTipoEntrega(res.data);
            }).catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        var sucursal = Auth.getSucursal();
        setSucursal(sucursal);
        getDescuentosFletes()
        obtenerTipoPagos(true);
        props.updateTipoEnvio(2);
        props.updateTipoPago("");
    }, [])

    return (
        <React.Fragment>
            <br />
            <div className="check-content">
                <div className="item" style={{ "padding-top": "15px" }}>
                    <p className="opc">Cliente:</p>
                    <p className="val">{
                        props.usuario ? (<React.Fragment>{sucursal.nombre}</React.Fragment>)
                            : ('')
                    }</p>
                </div>
                <hr style={Constants.style} />
                <div className="item" style={{ "padding-top": "15px" }}>
                    <p className="opc">Dirección:</p>
                    <p className="val">{props.usuario ? (
                        sucursal.direccion + ', ' +
                        sucursal.ciudad + ' '
                    ) : (null)}</p>
                </div>
                <hr style={Constants.style} />

                <div style={{ "padding-top": "15px" }}>
                    <p className="opcc">Opciones Envio:</p>
                    {tipoEntrega ? (
                        <React.Fragment>
                            {tipoEntrega.map((item, i) => (
                                <React.Fragment>
                                    <label className="valc" key={i}>
                                        <input key={i} onClick={e => handleFecha(e.target.value, item)} type="radio" name="envio" value={i} />{tipoEnvioResp(item)} {/* {item.valorFlete != 0 ? <> <br />{infoTipoEnvio(item)}<br /></> : ""} */}
                                    </label>
                                    <br></br>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ) : null}
                </div>
                <hr style={Constants.style} />

                {/* <div className="item" style={{ "padding-top": "15px" }}>
                    <p className="opc">Fecha de entrega:</p>
                    <p className="val">{fechaEntrega}</p>
                </div>
                <hr style={Constants.style} /> 
                <div className="item" style={{ "padding-top": "15px" }}>
                    <p className="opc">Método de pago:</p>
                    <p className="val">{sucursal.condicionPago !== "DI" ? "Crédito" : "Contra entrega, paga cuando recibas"}</p>
                </div>*/}
                <div style={{ "padding-top": "15px" }}>
                    <p className="opcc">Método de pago:</p>
                    <>
                        {listaTipoPagos.map((item, i) => (
                            <React.Fragment>
                                <label className="valc" key={i}>
                                    <input key={i} onClick={e => handleMetodoPago(e.target.value)} type="radio" name="pago" value={item.value} /> {item.descripcion}
                                </label>
                                <br></br>
                            </React.Fragment>
                        ))}
                    </>
                    {/*   <label className="valc">
                        <input onClick={e => handleMetodoPago(e.target.value)} type="radio" name="pago" value="WPL" /> Pago en línea
                    </label>
                    <br></br>

                    <label className="valc">
                        <input onClick={e => handleMetodoPago(e.target.value)} type="radio" name="pago" value={sucursal.condicionPago !== "DI" ? "CRE" : "CON"} /> {sucursal.condicionPago !== "DI" ? "Crédito" : "Contra entrega, paga cuando recibas"}
                    </label>
                    <br></br> */}

                </div>
                <hr style={Constants.style} />
                <div className="item" style={{ "padding-top": "15px" }}>
                    <textarea className="inputObservacion" type="textarea" onChange={e => handleObservacion(e.target.value)} placeholder="Observaciones"></textarea>
                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state)
    }
}

export default withRouter(connect(mapStateToProps, { ActualizarUsuario })(CheckOut));